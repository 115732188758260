import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { ICombinedProduct } from "../../../interfaces";

interface Props {
	orderDetails: ICombinedProduct[];
	columns: any;
}

export const DetailsTable: React.FC<Props> = ({ orderDetails, columns }) => {
	function addUniqueId(array: ICombinedProduct[]) {
		array.forEach((x: ICombinedProduct, index: number) => (x.id = index));
		return array;
	}

	return (
		<DataGrid
			className="bi__card_order_details"
			rows={addUniqueId(orderDetails)}
			columns={columns}
			disableColumnMenu={true}
			showColumnRightBorder={true}
			hideFooter={false}
			pageSize={7}
		/>
	);
};
