import React, { useState } from "react";
import { Shipment } from "./Shipment";
import { Invoices } from "./Invoices";
// import { AjusteDeLiquidacao } from "./AjusteDeLiquidacao";
import { Performance } from "./Performance";
import { Quantidade } from "./Quantidade";
import { toStringConverter } from "../tables/gestao/UpdateGestao";
import { PaginationComponent } from "../../ui/pagination/PaginationComponent";
import { TooltipText } from "../tables/gestao/Tooltip";

interface Props {
    shipments: any;
    setBillingsWindow: (billingWindow: boolean) => void;
    setBillingsId: (id: number) => void;
}

export const BillingTable: React.FC<Props> = ({ shipments, setBillingsWindow, setBillingsId }) => {
    const rowsPerPage = 10;

    const [paginatedData, setPaginatedData] = useState<any>(shipments.slice(0, rowsPerPage));
    const [openShipment, setOpenShipment] = useState<boolean>(false);
    const [openInvoice, setOpenInvoice] = useState<boolean>(false);
    const [shipmentId, setShipmentId] = useState<number>(0);
    const [invoiceRef, setInvoiceRef] = useState<string>("");

    return (
        <>
            <div className="table-main-cont whitebg">
                <div className="table-value-search-cont">
                    <div className="table-value">{shipments.length} shipments</div>
                </div>
                <div className="table-border-bottom"></div>
                <div className="billing-graph-cont">
                    <Performance data={shipments} />
                    <Quantidade data={shipments} />
                </div>
                <div className="table-overflow">
                    <table className="table-results">
                        <thead>
                            <tr className="table-results-title">
                                <th className="billing-medium">Shipment ID</th>
                                <th className="table-border-right"></th>
                                <th className="billing-medium">Vendor name</th>
                                <th className="table-border-right"></th>
                                <th className="billing-medium">Itens vendidos</th>
                                <th className="table-border-right"></th>
                                <th className="billing-small">Número de Pedidos</th>
                                <th className="table-border-right"></th>
                                <th className="billing-small">Valor recebido</th>
                                <th className="table-border-right"></th>
                                <th className="billing-small">Valor total da invoice</th>
                                <th className="table-border-right"></th>
                                <th className="billing-small">Billings</th>
                                <th className="table-border-right"></th>
                                <th className="billing-small">Invoices</th>
                                <th className="table-border-right"></th>
                                <th className="billing-medium">Status</th>
                                <th className="table-border-right"></th>
                                <th className="billing-small">Ajuste de Liquidação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedData.map((item: any, index: number) => (
                                <tr className="table-result-box billing-text" key={index}>
                                    <td className="billing-medium billing-underline">
                                        <div className="billing-redtext pointer" onClick={() => {
                                            setOpenShipment(true);
                                            setShipmentId(item.shipment_id);
                                        }}>
                                            Shipment {item.shipment_id}
                                        </div>
                                    </td>
                                    <td className="table-border-right"></td>
                                    <td className="billing-medium" style={{ justifyContent: "flex-start", paddingLeft: "5px" }}>
                                        <TooltipText text={item.partner_id} items={3} separator={" "} />
                                    </td>
                                    <td className="table-border-right"></td>
                                    <td className="billing-medium">
                                        <div className="billing-largetext">{item.total_number_of_items_sold}/{item.total_number_of_items_purchased}</div>
                                    </td>
                                    <td className="table-border-right"></td>
                                    <td className="billing-small">
                                        <div className="billing-text">{item.number_of_related_sale_orders !== 0 && item.number_of_related_sale_orders}</div>
                                    </td>
                                    <td className="table-border-right"></td>
                                    <td className="billing-small">
                                        <div className="billing-text">
                                            {!item.valor_recebido ? (
                                                <></>
                                            ) : (
                                                <div className="billing-cost">
                                                    {toStringConverter(item.valor_recebido)} €
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    <td className="table-border-right"></td>
                                    <td className="billing-small">
                                        <div className="billing-text">
                                            {!item.total_amount ? (
                                                <></>
                                            ) : (
                                                <div className="billing-cost">{item.total_amount} €</div>
                                            )}
                                        </div>
                                    </td>
                                    <td className="table-border-right"></td>
                                    <td className="billing-small underline ">
                                        {item.number_of_billings === 0 ? (
                                            <></>
                                        ) : (
                                            <div
                                                className="billing-text pointer"
                                                onClick={() => {
                                                    setBillingsId(item.shipment_id);
                                                    setBillingsWindow(true);
                                                }}
                                            >
                                                {item.number_of_billings}{" "}
                                                {item.number_of_billings > 1 ? "Billings" : "Billing"}
                                            </div>
                                        )}
                                    </td>
                                    <td className="table-border-right"></td>
                                    <td className="billing-small underline pointer" onClick={() => {
                                        setOpenInvoice(true);
                                        setInvoiceRef(item.bill_ref);
                                    }}>
                                        {!item.bill_ref ? <></> : <div>#{item.bill_ref}</div>}
                                    </td>
                                    <td className="table-border-right"></td>
                                    <td className="billing-medium">
                                        <div className="billing-text">{item.shipment_payment_status}</div>
                                    </td>
                                    <td className="table-border-right"></td>
                                    <td className="billing-small underline">
                                        {/* {!item.creditNotes.length ? <></> : <AjusteDeLiquidacao data={item.creditNotes} />} */}
                                        {item.name_of_note}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <PaginationComponent data={shipments} rowsPerPage={rowsPerPage} setPaginatedData={setPaginatedData} />
                {openShipment && <Shipment shipmentId={shipmentId} setOpenShipment={setOpenShipment} openShipment={openShipment} />}
                {openInvoice && <Invoices invoiceRef={invoiceRef} setOpenInvoice={setOpenInvoice} openInvoice={openInvoice} />}
            </div>
        </>
    );
}
