import React, { useState, useEffect } from 'react';
import "./notifications/noties.css";
import { withStyles } from '@material-ui/core/styles';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import { Noties } from "./notifications/Noties";
import { getNotifications } from "../../../actions/notifications";
import { INote } from "../../../interfaces";

export const StyledMenu = withStyles({
  paper: {
    background: '#FFFFFF',
    border: '1px solid #E1E1E1',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
    width: '400px',
    maxHeight: '480px',
    overflowY: 'auto',
    marginTop: '10px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export const NotiesSection = () => {
  const [noties, setNoties] = useState<INote[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    async function initializeState() {
      let notifications = await getNotifications();
      setNoties(notifications)
    }
    initializeState();
    // eslint-disable-next-line
  }, []);

  const allReadCallback = () => {
    const allReadNoties = noties.map((note: INote) => {
      if (!note.wasRead) {
        note.wasRead = true;
      }
      return note;
    });
    setNoties(allReadNoties as INote[]);
  }

  return (
    <div className="header-circle" onClick={(e) => setAnchorEl(e.currentTarget)}>
      <img src="/icons/notification.svg" alt="" />
      <div className="new-notification">
        <img
          src="/icons/new-notification.svg"
          width="20px"
          alt=""
        />
        <div className="number-notifications">{noties.length === 0 ? 0 : noties.filter((note: INote) => note.wasRead === false).length}</div>
      </div>
      {noties.length > 0 ? (
        <Noties noties={noties} anchorEl={anchorEl} setAnchorEl={setAnchorEl} allReadCallback={allReadCallback} />
      ) : (
        <StyledMenuComponent anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
          <span style={{ padding: '0px 6px' }}>There're no active notifications</span>
        </StyledMenuComponent>
      )}
    </div>
  )
}

interface INotiesMenu {
  anchorEl: null | HTMLElement;
  setAnchorEl: (anchorEl: null | HTMLElement) => void;
  children: React.ReactNode;
}

export const StyledMenuComponent: React.FC<INotiesMenu> = ({ anchorEl, setAnchorEl, children }) => {
  const onCloseNoties = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  }

  return (
    <StyledMenu
      id="customized-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onCloseNoties}
    >
      {children}
    </StyledMenu>
  )
}
