import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
  }),
);

interface Props {
  data: any;
  rowsPerPage: number;
  setPaginatedData: (data: any) => void;
  setCurrentPage?: any;
  currentPage?: number;
}

export const PaginationComponent: React.FC<Props> = ({ data, rowsPerPage, setPaginatedData, setCurrentPage, currentPage }) => {
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(rowsPerPage);

  const classes = useStyles();
  const pagesCount = Math.ceil(data.length / rowsPerPage);

  const handleChangePage = (e: any, newPage: number) => {
    e.preventDefault();
    const from = newPage * rowsPerPage - rowsPerPage;
    const to = newPage * rowsPerPage;
    setFrom(from);
    setTo(to);
    setPaginatedData(data.slice(from, to));
    if (typeof setCurrentPage === 'function') {
      setCurrentPage(newPage);
    }
  }

  return (
    <div className={`table-pagination ${classes.root}`}>
      <div className="table-pagination-text">Mostrando {from + 1} - {to > data.length ? data.length : to}</div>
      <Pagination
        page={currentPage}
        count={pagesCount}
        variant="outlined"
        shape="rounded"
        color="secondary"
        onChange={handleChangePage}
      />
    </div>
  );
}
