import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

interface IPropsText {
  text: string;
  items: number;
  separator: string;
}

export const TooltipText: React.FC<IPropsText> = ({ text, items, separator }) => {
  const string = text.split(separator).length > items
    ? text.split(separator).slice(0, items).join(separator) + "..."
    : text;

  return (
    <Tooltip title={text} className='pointer'>
      <span>{string}</span>
    </Tooltip>
  )
}
