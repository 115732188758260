import React from 'react';
import './menu.css';

interface Props extends React.PropsWithChildren {
    open: boolean;
    onClose: () => void;
}

const Menu: React.FC<Props> = ({ children, open, onClose }) => {
    return open ? (
        <>
            <div className='menu-overlay' onClick={onClose} />
            <div className='menu-wrap'>
                {children}
            </div>
        </>
    ) : <></>;
};

export default Menu;
