import React from 'react';
import './accessdenied.css';

const AccessDenied: React.FC<any> = () => {
    return (
        <div className="s_accessdenied">
            <div className="access_box">
                <h1 className="access_h1">Access Denied</h1>
            </div>
        </div>
    );
}

export default AccessDenied;
