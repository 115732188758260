import React, { useState } from "react";
import "./acompanhamento.css";
import "../tables.css";
import { Selects } from "./Selects";
import { TableAcompanhamento } from "./TableAcomp";
import Preloader from "../../../ui/preloader/Preloader";
import { DatePickersAcomp } from "./DatePickersAcomp";
import { IOrdersAcomp, IOrdersAcompB2 } from "../../../../interfaces";

export default function Acompanhamento() {
	const [loading, setLoading] = useState<boolean>(true);
	const [orders, setOrders] = useState<Array<IOrdersAcomp>>();
	const [filteredOrders, setFilteredOrders] = useState<Array<IOrdersAcompB2>>();

	const getOrdersCallback = ({ data }: any) => {
		setOrders(data.orders);
	};

	const filteredOrdersCallback = (orders: IOrdersAcompB2[]) => {
		setFilteredOrders(orders);
	};

	return (
		<>
			<div className="main-body">
				<div className="main-container">
					<div className="table-title-cont">
						<div className="table-title-sort">
							{orders && !loading ? (
								<Selects
									orders={orders}
									filteredOrdersCallback={filteredOrdersCallback}
								/>
							) : (
								<div className="acompan-preloader">
								</div>
							)}
							<DatePickersAcomp
								getOrdersCallback={getOrdersCallback}
								setLoading={setLoading}
								loading={loading}
							/>
						</div>
					</div>
					{filteredOrders && !loading ? (
						<TableAcompanhamento orders={filteredOrders} />
					) : (
						<Preloader />
					)}
				</div>
			</div>
		</>
	);
}
