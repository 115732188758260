import React, { forwardRef, useEffect, useState } from "react";
import "./businessIntelligence.css";
import "react-datepicker/dist/react-datepicker.css";
import * as _ from "lodash";
import DatePicker from "react-datepicker";
import { withStyles } from '@material-ui/core/styles';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import { ICombinedOrder, ICombinedOrders } from "../../../interfaces";
import moment from "moment";

interface Props {
  from: Date | number;
  setDateFrom: (date: Date | number) => void;
  setDate: (date: string) => void;
  to: Date | number;
  setDateTo: (date: Date | number) => void;
  section: string;
  setCountry: (country: string) => void;
  country: string;
  warehouse: string;
  setWarehouse: (warehouse: string) => void;
  category: string;
  setCategory: (category: string) => void;
  brand: string;
  setBrand: (brand: string) => void;
  setMarketplaceMarket: (marketplaceMarket: string) => void;
  marketplaceMarket: string;
  setMarketplaceAccount: (marketplaceMarket: string) => void;
  marketplaceAccount: string;
  orders: ICombinedOrders;
}

export const FilterSection: React.FC<Props> = ({
  from,
  setDateFrom,
  setDate,
  to,
  setDateTo,
  section,
  setCountry,
  country,
  warehouse,
  setWarehouse,
  category,
  setCategory,
  brand,
  setBrand,
  setMarketplaceMarket,
  marketplaceMarket,
  setMarketplaceAccount,
  marketplaceAccount,
  orders,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [aplicarDateFrom, setAplicarDateFrom] = useState<Date | number>(from);
  const [aplicarDateTo, setAplicarDateTo] = useState<Date | number>(to);
  const [aplicarCountry, setAplicarCountry] = useState<string>(country);
  const [aplicarWarehouse, setAplicarWarehouse] = useState<string>(warehouse);
  const [aplicarCategory, setAplicarCategory] = useState<string>(category);
  const [aplicarBrand, setAplicarBrand] = useState<string>(brand);
  const [aplicarMarketplaceMarket, setAplicarMarketplaceMarket] = useState<string>(marketplaceMarket);
  const [aplicarMarketplaceAccount, setAplicarMarketplaceAccount] = useState<string>(marketplaceAccount);

  useEffect(() => {
    setAplicarCountry("All Countries");
  }, [section])

  const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <div className="bi__calendar_input" onClick={onClick} ref={ref}>
      {value}
    </div>
  ));

  const renderSelects = (section: string) => {
    switch (section) {
      case 'General':
        return <CountrySelects country={aplicarCountry} setCountry={setAplicarCountry} orders={orders} />;
      case 'B2B':
        return <CountrySelects country={aplicarCountry} setCountry={setAplicarCountry} orders={orders} />;
      case 'B2C':
        return <B2CSelects
          category={aplicarCategory}
          setCategory={setAplicarCategory}
          country={aplicarCountry}
          setCountry={setAplicarCountry}
          setMarketplaceMarket={setAplicarMarketplaceMarket}
          marketplaceMarket={aplicarMarketplaceMarket}
          setMarketplaceAccount={setAplicarMarketplaceAccount}
          marketplaceAccount={aplicarMarketplaceAccount}
          orders={orders}
        />;
      default:
        return;
    }
  }

  const onAplicarClick = (e: any) => {
    e.preventDefault();
    setDateFrom(aplicarDateFrom);
    setDateTo(aplicarDateTo);
    setDate("Selecione período");
    setCountry(aplicarCountry);
    setWarehouse(aplicarWarehouse);
    setCategory(aplicarCategory);
    setBrand(aplicarBrand);
    setMarketplaceMarket(aplicarMarketplaceMarket);
    setMarketplaceAccount(aplicarMarketplaceAccount);
  }

  const resetFilters = (e: any) => {
    e.preventDefault();
    setAplicarDateFrom(Date.parse(`${moment().startOf("months")}`));
    setAplicarDateTo(new Date().valueOf());
    setDate("Selecione período");
    setAplicarCountry("All Countries");
    setAplicarWarehouse("All warehouses");
    setAplicarCategory("All Categories");
    setAplicarBrand("All brands");
    setAplicarMarketplaceMarket("All Webstores");
    setAplicarMarketplaceAccount("All Marketplaces");
  }

  return (
    <>
      <div className="table-title-sort">
        <div className="gestao-select-cont">
          <button
            className="main-select-bi"
            onClick={(e) => {
              e.preventDefault();
              setAnchorEl(e.currentTarget);
            }}
          >
            FILTRAR
          </button>
        </div>
      </div>
      {anchorEl !== null && (
        <StyledMenuComponent anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
          <div className="filter-container">
            <div className="filter-header">
              <div className="filter-header-title">Filtrar</div>
              <div className="filter-header-reset" onClick={resetFilters}>Limpar filtros</div>
            </div>
            <div className="filter-line"></div>
            <div className="filter-content">
              {section !== "S&OP" && (<div className="filter-datepicker">
                <div className="filter-dates">
                  <div className="filter-dates-box">
                    <div className="filter-dates-label">From</div>
                    <div className="filter-dates-calendar">
                      <div>
                        <DatePicker
                          selected={aplicarDateFrom as Date}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date: any) => {
                            setAplicarDateFrom(Date.parse(date));
                          }}
                          customInput={<ExampleCustomInput />}
                        />
                      </div>
                      <div className="filter-calendar-icon">
                        <img src="/icons/calendar.svg" alt="calendar" />
                      </div>
                    </div>
                  </div>
                  <div className="filter-dates-box">
                    <div className="filter-dates-label">To</div>
                    <div className="filter-dates-calendar">
                      <div>
                        <DatePicker
                          selected={aplicarDateTo as Date}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date: any) => {
                            setAplicarDateTo(moment(date).endOf("day").valueOf());
                          }}
                          customInput={<ExampleCustomInput />}
                        />
                      </div>
                      <div className="filter-calendar-icon">
                        <img src="/icons/calendar.svg" alt="calendar" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>)}
              <div className="filter-selects-cont">
                {renderSelects(section)}
              </div>
              <div className="filter-buttons">
                <button
                  onClick={onAplicarClick}
                  className="filter-apply-btn"
                >
                  APLICAR
                </button>
                <button className="filter-cancel-btn" onClick={() => setAnchorEl(null)}>Cancelar</button>
              </div>
            </div>
          </div>
        </StyledMenuComponent>
      )}
    </>
  );
};

const StyledMenu = withStyles({
  paper: {
    background: '#FFFFFF',
    border: '1px solid #E1E1E1',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
    width: '360px',
    height: '500px',
    overflowY: 'auto',
    marginTop: '10px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

interface IBIFilter {
  anchorEl: null | HTMLElement;
  setAnchorEl: (anchorEl: null | HTMLElement) => void;
  children: React.ReactNode;
}

const StyledMenuComponent: React.FC<IBIFilter> = ({ anchorEl, setAnchorEl, children }) => {
  const onCloseFilter = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  }

  return (
    <StyledMenu
      id="customized-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onCloseFilter}
    >
      {children}
    </StyledMenu>
  )
}

const CountrySelects: React.FC<any> = ({ country, setCountry, orders }) => {
  function createArray(obj: ICombinedOrders) {
    return [...obj.b2b, ...obj.b2c];
  }

  function countriesArray(array: ICombinedOrder[]) {
    return [
      "All Countries",
      ..._.uniq(_.map(array, (item: ICombinedOrder) => item.country)),
    ];
  }

  return (
    <div>
      <select
        className="filter-select"
        value={country}
        onChange={(e) => {
          setCountry(e.target.value);
        }}
      >
        {countriesArray(createArray(orders)).map((item: string, key: number) => (
          <option key={key}>{item}</option>
        ))}
      </select>
    </div>
  )
}

const B2CSelects: React.FC<any> = ({
  country,
  setCountry,
  category,
  setCategory,
  marketplaceMarket,
  setMarketplaceMarket,
  marketplaceAccount,
  setMarketplaceAccount,
  orders,
}) => {
  function countriesArray(array: ICombinedOrder[]) {
    return [
      "All Countries",
      ..._.uniq(_.map(array, (item: ICombinedOrder) => item.country)),
    ];
  }

  function categoriesArray(array: ICombinedOrder[]) {
    const products = array.flatMap((x: ICombinedOrder) => x.sale_line_ids);
    const otherCategories = Object.keys(_.groupBy(products, "category_id"));
    return ["All Categories", ...otherCategories];
  }

  function webstoresArray() {
    return ["All Webstores", ..._.uniq(
      orders.b2c.map((x: ICombinedOrder) => x.sale_marketplace)
    )]
  }

  function marketplacesArray() {
    return ["All Marketplaces", ..._.uniq(
      orders.b2c.map((x: ICombinedOrder) => x.sale_marketplace_account)
    )]
  }

  return (
    <>
      <div>
        <select
          className="filter-select"
          value={country}
          onChange={(e) => {
            setCountry(e.target.value);
            setCategory("All Categories");
            setMarketplaceMarket("All Webstores");
            setMarketplaceAccount("All Marketplaces");
          }}
        >
          {countriesArray(orders.b2c).map((item: string, key: number) => (
            <option key={key}>{item}</option>
          ))}
        </select>
      </div>
      <div>
        <select
          className="filter-select"
          value={category}
          onChange={(e) => {
            setCategory(e.target.value);
            setCountry("All Countries");
            setMarketplaceMarket("All Webstores");
            setMarketplaceAccount("All Marketplaces");
          }}
        >
          {categoriesArray(orders.b2c).map((item: string, key: number) => (
            <option key={key}>{item}</option>
          ))}
        </select>
      </div>
      <div>
        <select
          className="filter-select"
          value={marketplaceMarket}
          onChange={(e) => {
            e.preventDefault();
            setMarketplaceMarket(e.target.value);
            setMarketplaceAccount("All Marketplaces");
            setCountry("All Countries");
            setCategory("All Categories");
          }}
        >
          {_.map(webstoresArray(), (item: string, index: number) => (
            <option className="" key={index} >
              {item}
            </option>
          ))}
        </select>
      </div>
      <div>
        <select
          className="filter-select"
          value={marketplaceAccount}
          onChange={(e) => {
            e.preventDefault();
            setMarketplaceMarket("All Webstores");
            setMarketplaceAccount(e.target.value);
            setCountry("All Countries");
            setCategory("All Categories");
          }}
        >
          {_.map(marketplacesArray(), (item: string, index: number) => (
            <option className="" key={index} >
              {item}
            </option>
          ))}
        </select>
      </div>
    </>
  )
}
