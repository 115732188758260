import React, { useState, useMemo, useEffect, memo } from "react";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import Login from "./routes/login/Login";
import Esqueci from "./routes/login/Esqueci";
import Reset from "./routes/login/Reset";
import { refreshTokens } from "../actions/user";
import { UserContext, CountriesContext } from "./context";
import PrivateRoute from "./utils/private-route/PrivateRoute";
import AccessDenied from "./routes/accessdenied/AccessDenied";
import WrapBI from "./routes/business-intelligence/BusinessIntelligence";
import Billing from "./routes/billing/Billing";
import Profile from "./routes/profile/Profile";
import Gestao from "./routes/tables/gestao/Gestao";
import Comparador from "./routes/tables/comparador/Comparador";
import Acompanhamento from "./routes/tables/acompanhamento/Acompanhamento";
import CssBaseline from '@material-ui/core/CssBaseline';
import ToastMessage from './ui/toast-message/ToastMessage';
import PanelBar from "./ui/panel-bar/PanelBar";
import "./app.css";
import { Requests } from "./ui/header/requests/Requests";
import Preloader from "./ui/preloader/Preloader";
import { getCountries } from "../actions/countryManagement";
import { QueryClient, QueryClientProvider } from "react-query";
import { ICountryM } from "./ui/country-flag/CountryFlag";

export const queryClient = new QueryClient();

export default memo(function App() {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const [countries, setCountries] = useState<ICountryM[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const userValue = useMemo(() => ({ user, setUser }), [user, setUser]);

  useEffect(() => {
    async function initializeState() {
      try {
        const { user } = await refreshTokens();
        setUser(user);
        setCountries(await getCountries());
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    }
    initializeState();
  }, []);

  if (loading) {
    return (
      <div className="center-loader">
        <Preloader />
      </div>
    );
  }

  return (
    <QueryClientProvider contextSharing={true} client={queryClient}>
      <BrowserRouter>
        <UserContext.Provider value={userValue}>
          <CountriesContext.Provider value={countries}>
            <ToastMessage />
            {user ? (
              <div className="root">
                <CssBaseline />
                <PanelBar setOpen={setOpen} open={open} />
                <main
                  className={open ? "content-shift" : "content"}
                >
                  <div className="drawer-header" />
                  <Switch>
                    <Route path="/login" exact>
                      <Login />
                    </Route>
                    <Route path="/accessdenied" exact>
                      <AccessDenied />
                    </Route>
                    <PrivateRoute path={["/", "/bi"]} exact>
                      <WrapBI />
                    </PrivateRoute>
                    <PrivateRoute path="/profile" exact>
                      <Profile />
                    </PrivateRoute>
                    <PrivateRoute path="/gestao" exact>
                      <Gestao />
                    </PrivateRoute>
                    <PrivateRoute path="/comparador" exact>
                      <Comparador />
                    </PrivateRoute>
                    <PrivateRoute path="/acompanhamento" exact>
                      <Acompanhamento />
                    </PrivateRoute>
                    <PrivateRoute path="/requests" exact>
                      <Requests />
                    </PrivateRoute>
                    <PrivateRoute path="/billing" exact>
                      <Billing />
                    </PrivateRoute>
                  </Switch>
                </main>
              </div>
            ) : (
              <Switch>
                <Route path="/login/esqueci" exact>
                  <Esqueci />
                </Route>
                <Route path="/login/resetpass/:token" exact>
                  <Reset />
                </Route>
                <Route path="*">
                  <Login />
                </Route>
              </Switch>
            )}
          </CountriesContext.Provider >
        </UserContext.Provider >
      </BrowserRouter >
    </QueryClientProvider>
  );
});
