import React, { useCallback, useEffect, useState } from "react";
import "./gestao.css";
import "../tables.css";
import { UpdateGestao } from "./UpdateGestao";
import { IProductGestao, IMarketplace, IPaginatedRequest } from "../../../../interfaces";
import { getPaginatedInventoryProducts } from "../../../../actions/marketplace";
import { TooltipText } from "./Tooltip";
import { convertPrice } from "./UpdateGestao";
import { useDebouncedValue } from "@mantine/hooks";
import Preloader from "../../../ui/preloader/Preloader";
import { useQuery } from "react-query";
import Paginator from "../../../ui/pagination";

export function getMarketplaceProductImage(product: any) {
	return product.image || product.pack;
}

interface Props {
	country: string;
	marketplaces: IMarketplace[];
}

export const TableGestao: React.FC<Props> = ({
	country,
	marketplaces,
}) => {
	const [search, setSearch] = useState<string>("");
	const [debouncedSearch] = useDebouncedValue(search, 200);
	const [modal, setModal] = useState<boolean>(false);
	const [product, setProduct] = useState<IProductGestao | undefined>();
	const [marketplace, setMarketplace] = useState<IMarketplace>();
	const [refetcher, setRefetcher] = useState(0);
	const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
		perPage: 50,
		page: 1,
		search: debouncedSearch,
	});

	const {
		isLoading,
		data: products,
		refetch,
	} = useQuery("products", () => getPaginatedInventoryProducts(currentConfig));

	useEffect(() => {
		refetch();
		// eslint-disable-next-line
	}, [currentConfig]);

	useEffect(() => {
		setCurrentConfig((currentConfig) => ({
			...currentConfig,
			page: 1,
			search: debouncedSearch,
		}));
	}, [debouncedSearch, refetcher]);

	const onChangePage = useCallback(
		async (config: IPaginatedRequest) => {
			config.search = debouncedSearch;
			setCurrentConfig(config);
		},
		[debouncedSearch]
	);

	if (isLoading) {
		return (
			<div className="center-loader">
				<Preloader />
			</div>
		);
	}

	return (
		<>
			<div className="table-main-cont">
				<div className="table-value-search-cont">
					<div className="table-value-search-area">
						<div className="table-value">
							<span>{products.total} produtos em {country}</span>
						</div>
						<div className="table-search-box">
							<form className="table-search-form" onSubmit={(e) => e.preventDefault()}>
								<input
									className="table-search-input"
									placeholder="Search product"
									onChange={(e) => setSearch(e.target.value)}
								/>
								<img
									className="table-search-image"
									src="/icons/search.svg"
									width="25px;"
									alt=""
								/>
							</form>
						</div>
					</div>
					<div className="marketplace-pagination">
						<Paginator data={products} refetch={onChangePage as any} />
					</div>
				</div>
				<div className="table-border-bottom"></div>
				<div className="table-overflow">
					<table style={{ position: "sticky", zIndex: 101, top: "0" }}>
						<tr style={{ marginTop: "-5px" }} className="table-results-title">
							<th className="gestao-sticky-block redbg title-border">
								<th className="gestao-image-link"></th>
								<th className="table-border-right"></th>
								<th className="gestao-image"></th>
								<th className="table-border-right"></th>
								<th className="gestao-sku">SKU</th>
								<th className="table-border-right"></th>
								<th className="gestao-ean">EAN</th>
								<th className="table-border-right"></th>
								<th className="gestao-title">Title</th>
								<th className="table-border-right"></th>
							</th>
							{/* <th className="gestao-total">Estoque Total</th> */}
							{marketplaces.map((marketplace: any, index: number) => (
								<React.Fragment key={index}>
									<th className="table-border-right"></th>
									<th className="gestao-vinuus pt1">
										{marketplace.zeoosName}
										<div className="in-row width100 height100 gestao-sort mt1">
											<div className="gestao-cost justify-center">
												Price
											</div>
											<th className="table-border-right"></th>
											<div className="gestao-stock justify-center">
												Stock
											</div>
										</div>
									</th>
								</React.Fragment>
							))}
						</tr>
					</table>
					<div >
						<table className="table-results p0">
							<thead>
							</thead>
							<tbody>
								{products?.data.map((item: IProductGestao, index: number) => (
									<tr className="table-result-box" key={index}>
										<td className="gestao-sticky-block">
											<td className="gestao-image-link">
												<img
													src={
														!item.components?.length
															? `/icons/group-p.svg`
															: `/icons/arrow-return-right.svg`
													}
													alt=""
												/>
											</td>
											<td className="table-border-right"></td>
											<td className="gestao-image">
												<img
													className="gestao-table-image"
													src={getMarketplaceProductImage(item)}
													alt=""
												/>
											</td>
											<td className="table-border-right"></td>
											<td className="gestao-sku">
												<div className="gestao-sku-text">
													<TooltipText text={item.sku} items={7} separator={""} />
												</div>
											</td>
											<td className="table-border-right"></td>
											<td className="gestao-ean">
												<div className="gestao-ean-text">{item.barcode}</div>
											</td>
											<td className="table-border-right"></td>
											<td className="gestao-title">
												<div className="gestao-title-text">
													<TooltipText text={item.name} items={20} separator={" "} />
												</div>
											</td>
											<td className="table-border-right"></td>
										</td>
										{/* <td className="gestao-total">
											<div className="gestao-total-text">{item.stock}</div>
										</td> */}
										{marketplaces.map((marketplace: any, index: number) => {
											const data = item.marketplaces && item.marketplaces[marketplace.zeoosName] ? item.marketplaces[marketplace.zeoosName] : {};
											data.price = data.price || '0';
											data.stock = data.stock || 0;

											return (
												<React.Fragment key={index}>
													<td className="table-border-right"></td>
													<td
														onClick={() => {
															setModal(true);
															setProduct(item);
															setMarketplace(marketplace);
														}}
														className="gestao-vinuus pointer"
													>
														<div className="in-row width100 height100">
															<div className="gestao-cost">
																{/* <div style={{
																	backgroundColor: data.integrated ? '#00a224' : '#af1b3f',
																	width: 10,
																	height: 10,
																	borderRadius: '50%',
																	position: "absolute",
																	top: 0,
																	left: 0,
																	marginTop: '2px',
																	marginLeft: '4px',
																}}
																/> */}
																<div className="gestao-cost-text">
																	{convertPrice(data.price) || 0} €
																</div>
															</div>
															<td className="table-border-right"></td>
															<div className="gestao-stock">
																<div className="gestao-stock-text">
																	{(data.stock).toLocaleString("de-DE", {}) || 0}
																</div>
															</div>
															{/* <div className="gestao-cost-update">update</div> */}
														</div>
													</td>
												</React.Fragment>
											);
										})}
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
				<div>
				</div>
			</div>
			{
				modal && (
					<UpdateGestao
						modal={modal}
						setModal={setModal}
						product={product}
						onUpdateCallback={() => setRefetcher((prev) => prev + 1)}
						productMarketplace={marketplace as IMarketplace}
					/>
				)
			}
		</>
	);
};
