import React from "react";
import "./businessIntelligence.css";

interface Props {
  setSection: (country: string) => void;
  section: string;
}

export const SelectSection: React.FC<Props> = ({
  setSection,
  section,
}) => {
  const sections = ["B2C", "FlashSales"];

  return (
    <div className="table-title-sort">
      <div className="gestao-select-cont">
        {sections.map((item: any, index: number) => (
          <button
            className={`gestao-select-button ${section === item ? "gestao-active" : ""
              }`}
            key={index}
            onClick={(e) => {
              e.preventDefault();
              setSection(item);
            }}
          >
            {item}
          </button>
        ))}
      </div>
    </div>
  );
};
