import React, { useEffect, useState } from "react";
import { Screen } from "../../ui/screen/Screen";
import "./businessIntelligence.css";
import { Cards } from "./Cards";
import Preloader from "../../ui/preloader/Preloader";
import { ICombinedOrders } from "../../../interfaces";
import { getOrders } from "../../../actions/bi";
import moment from "moment";
import { FilterSection } from "./FilterSection";
import { SelectSection } from "./SelectSection";
import { DateSelect } from "./DateSelect";

const BusinessIntelligence = () => {
  const [orders, setOrders] = useState<ICombinedOrders | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [section, setSection] = useState<string>("B2C");

  const [country, setCountry] = useState<string>("All Countries");
  const [warehouse, setWarehouse] = useState<string>("All warehouses");
  const [category, setCategory] = useState<string>("All Categories");
  const [brand, setBrand] = useState<string>("All brands");
  const [marketplaceMarket, setMarketplaceMarket] = useState<string>("All Webstores");
  const [marketplaceAccount, setMarketplaceAccount] = useState<string>("All Marketplaces");

  const [date, setDate] = useState<string>("This Month");
  const [from, setDateFrom] = useState<Date | number>(
    Date.parse(`${moment().startOf("months")}`)
  );
  const [to, setDateTo] = useState<Date | number>(new Date().valueOf());

  useEffect(() => {
    async function initializeState() {
      setLoading(true);
      const ordersData = await getOrders({
        from,
        to,
      });
      setOrders(ordersData.data.orders);
      setLoading(false);
    }
    initializeState();
    // eslint-disable-next-line
  }, [from, to]);

  return (
    <div className="main-body">
      <div className="main-container">
        {orders && !loading ? (
          <>
            <div className="bi-header-cont">
              <SelectSection section={section} setSection={setSection} />
              <div className="bi-filters-cont">
                {section !== "S&OP" && <DateSelect setDateFrom={setDateFrom} setDateTo={setDateTo} date={date} setDate={setDate} />}
                <FilterSection
                  from={from}
                  to={to}
                  section={section}
                  setDateFrom={setDateFrom}
                  setDateTo={setDateTo}
                  setDate={setDate}
                  setCountry={setCountry}
                  country={country}
                  warehouse={warehouse}
                  setWarehouse={setWarehouse}
                  category={category}
                  setCategory={setCategory}
                  brand={brand}
                  setBrand={setBrand}
                  setMarketplaceMarket={setMarketplaceMarket}
                  marketplaceMarket={marketplaceMarket}
                  setMarketplaceAccount={setMarketplaceAccount}
                  marketplaceAccount={marketplaceAccount}
                  orders={orders}
                />
              </div>
            </div>
            <Cards
              orders={orders}
              section={section}
              country={country}
              category={category}
              marketplaceMarket={marketplaceMarket}
              marketplaceAccount={marketplaceAccount}
            />
          </>
        ) : (
          <Preloader />
        )}
      </div>
    </div >
  );
};

export default function WrapBI() {
  const [timeInterval, setTimeInterval] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeInterval((timeInterval) => timeInterval + 1);
    }, 900000);
    return () => {
      setTimeout(() => {
        setTimeInterval(0);
      }, 15000);
      clearInterval(interval);
    };
  }, [timeInterval]);

  return timeInterval ? <Screen /> : <BusinessIntelligence />;
}
