import React, { useEffect, useState } from "react";
import "./gestao.css";
import "../tables.css";
import { getSanitizedMarketplaces } from "../../../../actions/marketplace";
import { SelectCountry } from "./SelectCountry";
import { TableGestao } from "./TableGestao";
import Preloader from "../../../ui/preloader/Preloader";
import { IMarketplace } from "../../../../interfaces";

export default function Gestao() {
	const [loading, setLoading] = useState<boolean>(false);
	const [country, setCountry] = useState<string>("All Countries");
	const [marketplaces, setMarketplaces] = useState<IMarketplace[]>([]);

	useEffect(() => {
		async function initializeState() {
			setLoading(true);

			const marketplacesRes = await getSanitizedMarketplaces();
			setMarketplaces(marketplacesRes.data);
			setLoading(false);
		}
		initializeState();
		// eslint-disable-next-line
	}, []);

	const filteredByCountryMarketplaces =
		country === "All Countries"
			? marketplaces
			: marketplaces.filter((x: any) => x.country === country);

	return (
		<>
			<div className="main-body">
				<div className="main-container">
					{!loading ? (
						<>
							<SelectCountry
								setCountry={setCountry}
								country={country}
								marketplaces={marketplaces}
							/>
							<TableGestao
								country={country}
								marketplaces={filteredByCountryMarketplaces}
							/>
						</>
					) : (
						<Preloader />
					)}
				</div>
			</div>
		</>
	);
}
