import React from "react";
import "./businessIntelligence.css";
import moment from "moment";

interface Props {
  setDateFrom: (date: Date | number) => void;
  setDateTo: (date: Date | number) => void;
  date: string;
  setDate: (date: string) => void;
}

interface IDates {
  label: string;
  formatDate: string;
}

export const DateSelect: React.FC<Props> = ({
  setDateFrom,
  setDateTo,
  date,
  setDate,
}) => {

  const dates = [
    {
      label: "Yesterday",
      formatDate: `${moment().subtract(1, "days").startOf("day")}`,
    },
    {
      label: "Last 24 hours",
      formatDate: `${moment().subtract(1, "days")}`,
    },
    {
      label: "Today",
      formatDate: `${moment().startOf("day")}`,
    },
    {
      label: "Last Week",
      formatDate: `${moment().subtract(1, "weeks")}`,
    },
    {
      label: "This Month",
      formatDate: `${moment().startOf("months")}`,
    },
    {
      label: "Last Month",
      formatDate: `${moment().subtract(1, "months")}`,
    },
    {
      label: "Last Year",
      formatDate: `${moment().subtract(12, "months")}`,
    },
  ];

  return (
    <div className="dateB__wrapper">
      <div className="dateB__container">
        <label className="dateB__label">Período:</label>
        <select
          id="compa-channels"
          name="compa-channels"
          required
          className="main-select-bi"
          value={date}
          onChange={(e) => {
            setDate(e.target.value);
            setDateFrom(Date.parse(dates.find((x: IDates) => x.label === e.target.value)!.formatDate as string));
            setDateTo(new Date().valueOf());
          }}
        >
          {dates.map((item: IDates, index: number) => (
            <option className="" key={index} >
              {item.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
