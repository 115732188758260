import React, { useContext } from "react";
import "./navigation.css";
import { NavLink } from "react-router-dom";
import { UserContext } from "../../context";

export default function Navigation() {
  const { user } = useContext(UserContext);

  return (
    <>
      {user ? (
        <>
          <div className="main-nav">
            <div className="navigation-cont">
              <div className="titel_box">
                <NavLink to="/">
                  <img src="/icons/logo.svg" alt="" />
                </NavLink>
              </div>
              <div className="border-box"></div>
              <div className="navigation-menu-cont">
                <NavLink activeClassName="navigation-menu-box-active" to="/bi" className="navigation-menu-box">
                  <img
                    className="navigation-image"
                    src="/icons/dashboard.svg"
                    width=""
                    alt=""
                  />
                  <div className="navigation-menu-box-text">Dashboard</div>
                </NavLink>
                <NavLink activeClassName="navigation-menu-box-active" to="/gestao" className="navigation-menu-box">
                  <img
                    className="navigation-image"
                    src="/icons/gestaodoinventario.svg"
                    width=""
                    alt=""
                  />
                  <div className="navigation-menu-box-text">
                    Inventory
                  </div>
                </NavLink>
                <NavLink
                  activeClassName="navigation-menu-box-active"
                  to="/acompanhamento"
                  className="navigation-menu-box"
                >
                  <div className="nav-image-box">
                    <img
                      className="navigation-image"
                      src="/icons/acompanhamentodepedidos.svg"
                      width=""
                      alt=""
                    />
                  </div>
                  <div className="navigation-menu-box-text">Orders</div>
                </NavLink>
                <NavLink activeClassName="navigation-menu-box-active" to="/billing" className="navigation-menu-box">
                  <img
                    className="navigation-image billing-image"
                    src="/icons/billing-icon.svg"
                    width=""
                    alt=""
                  />
                  <div className="navigation-menu-box-text">
                    Billings
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
