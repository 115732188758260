import axios from "axios";
import { getToken, setToken } from "./token";
import * as accessToken from "../components/context";

axios.defaults.baseURL = !!process.env.REACT_APP_DEV
	? process.env.REACT_APP_serverLink + "/api"
	: "/api";

export function getHeaders(optionalHeaders = {}) {
	return {
		headers: {
			authorization: `Bearer ${accessToken.getToken()}`,
			...optionalHeaders,
		},
	};
}

export const loginUser = async (body: any, keepLoggedIn = true) => {
	const res = await axios.post("/user/login", body);

	const { data, success } = res.data;

	if (!success) {
		return new Error("Login failed");
	}

	accessToken.setToken(data?.user?.accessToken);

	if (keepLoggedIn) {
		setToken(data.refreshToken);
	}

	return data;
};

export const refreshTokens = async () => {
	const refreshToken = getToken();

	if (!refreshToken) {
		return new Error("Refresh token is not set");
	}

	const res = await axios.post("/user/refreshTokens", { refreshToken });

	const { data, success } = res.data;

	if (!success) {
		return new Error("Refresh tokens failed");
	}

	setToken(data.refreshToken);
	accessToken.setToken(data?.user?.accessToken);

	return data;
};

export async function getUsers() {
	const res = await axios.get("/user", getHeaders());
	return res.data.users;
}

export async function createUser(user: any) {
	await axios.post("/user", { user }, getHeaders());
}

export async function updateUser(username: string, user: any) {
	await axios.put(`/user/${username}`, { user }, getHeaders());
}

export async function updatePassword(
	password: string,
	currentPassword: string
  ) {
	await axios.post(
	  `/user/changePassword`,
	  { password, currentPassword },
	  getHeaders()
	);
  }
  
  export async function deleteProfileImage() {
	await axios.delete(`/user/image`, getHeaders());  
}

export async function getVendors() {
	const res = await axios.get("/getVendors", getHeaders());
	return res.data.vendors;
}

export async function deleteUser(username: any) {
	await axios.delete(`/user/${username}`, getHeaders());
}

export async function countryUser() {
	const res = await axios.get(`/shopify/url`, getHeaders());
	return res.data;
}

export async function forgotPassword(body: any) {	
	const res = await axios.post("/user/forgot", body);
	return res.data;
}

export async function resetPassword(body: any) {	
	const res = await axios.post("/user/reset", body);
	return res.data;
}
