import React, { useContext } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { UserContext } from '../../context';

const PrivateRoute: React.FC<any> = ({ children, ...props }) => {
    const { user } = useContext(UserContext);
    return (
        <Route
            {...props}
            render={({ location }) =>
                user ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

export default withRouter(PrivateRoute);
