import React, { forwardRef, useEffect, useState } from "react";
import "./acompanhamento.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getOrders } from "../../../../actions/bi";
import moment from "moment";

interface Props {
  getOrdersCallback: (data: any) => void;
  setLoading: (loading: boolean) => void;
  loading: boolean;
}

export const DatePickersAcomp: React.FC<Props> = ({
  getOrdersCallback,
  setLoading,
  loading,
}) => {
  const [from, setDateFrom] = useState<Date | number>(
    Date.parse(`${moment().startOf("months")}`
    ));
  const [to, setDateTo] = useState<Date | number>(new Date().valueOf());

  useEffect(() => {
    async function initializeState() {
      setLoading(true);
      const ordersData = await getOrders({
        from,
        to,
      });
      getOrdersCallback(ordersData);
      setLoading(false);
    }
    initializeState();
    // eslint-disable-next-line
  }, [from, to]);

  const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <div className="acompan-calendar-input" onClick={onClick} ref={ref}>
      {value}
    </div>
  ));

  if (loading) return <> </>;

  return (
    <>
      <div className="acompan-dates">
        <div>
          {/* <div className="acompan-labels">From</div> */}
          <div className="acompan-calendar">
            <div>
              <DatePicker
                selected={from as Date}
                dateFormat="dd/MM/yyyy"
                onChange={(date: any) => {
                  setDateFrom(Date.parse(date));
                }}
                customInput={<ExampleCustomInput />}
              />
            </div>
            <div className="acompan-calendar-icon">
              <img src="/icons/calendar.svg" alt="calendar" />
            </div>
          </div>
        </div>
        <div>
          {/* <div className="acompan-labels">To</div> */}
          <div className="acompan-calendar">
            <div>
              <DatePicker
                selected={to as Date}
                dateFormat="dd/MM/yyyy"
                onChange={(date: any) => {
                  setDateTo(moment(date).endOf("day").valueOf());
                }}
                customInput={<ExampleCustomInput />}
              />
            </div>
            <div className="acompan-calendar-icon">
              <img src="/icons/calendar.svg" alt="calendar" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
