import React, { useState, useContext } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import "./header.css";
import { Link, NavLink } from "react-router-dom";
import { UserContext } from "../../context";
import LogoutWrap from "../../utils/logout-wrap/LogoutWrap";
import Menu from "../../ui/menu/Menu";
import { UpdateProfile } from "./UpdateProfile";
import { NotiesSection } from "./NotiesSection";

type PathType = {
  path: string;
  label: string;
};

const labels: Array<PathType> = [
  { path: "/billing", label: "Billing management" },
  { path: "/bi", label: "Dashboard" },
  { path: "/marketplaces", label: "Canais de Venda" },
  { path: "/marketplace/", label: "Canal de Venda" },
  { path: "/gestao", label: "Gestão do Inventário" },
  { path: "/comparador", label: "Comparador de Preços" },
  { path: "/acompanhamento", label: "Acompanhamento de Pedidos" },
  { path: "/profile", label: "Profile" },
  { path: "/users-management", label: "Users-management" },
  { path: "/reviews", label: "Reviews" },
  { path: "/translator", label: "Translator" },
  { path: "/requests", label: "Requests" },
  { path: "/tax", label: "Tax mapping" },
  { path: "/freight", label: "Freight Mapping" },
  { path: "/pim-management", label: "Pim management" },
  { path: "/pim/scheme", label: "Pim scheme" },
  { path: "/country-management", label: "Country management" },
  { path: "/vendors-management", label: "Vendors management" },
  { path: "/productInfo", label: "Pim" },
  { path: "/file-management", label: "File management" },
];

const Header: React.FC<RouteComponentProps<any>> = (props) => {
  const { user } = useContext(UserContext);

  const [openMenu, setOpenMenu] = useState(false);
  const [modal, setModal] = useState<boolean>(false);

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal(false);
  };

  const pimSkuFromPath = (path: string) => {
    if (path.includes("/productInfo/")) {
      const pathArr = path.split("/");
      return `/ ${pathArr[pathArr.length - 1]}`;
    } else {
      return ""
    }
  }

  const pathname = props.history.location.pathname;
  const label = pathname === "/" ? "Dashboard" : `${labels.find((x: any) => pathname.includes(x.path))?.label} ${pimSkuFromPath(pathname)}`;

  return (
    <>
      {user ? (
        <div className="navbar-2">
          <div className="container">
            <div className="panel-menu-box">
              <div className="breadcrumbs">
                <Link to="/">
                  <img className="home-image" src="/icons/home.svg" alt="" />
                </Link>
                <div className="breadcrumbs-box">
                  <div className="margin-left5">/</div>
                  <div className="margin-left5">
                    {label}
                  </div>
                </div>
              </div>
            </div>
            <div className="header-button-box">
              <div className="header-circle-cont">
                <NavLink to="/requests">
                  <div className="header-circle">
                    <img src="/icons/help-support.svg" alt="" />
                  </div>
                </NavLink>
                <NotiesSection />
              </div>
              <div onClick={() => {
                setModal(true);
              }}>
                <div className="profile-box-header pointer">Hey, {user.username}</div>
              </div>
              <div>
                <LogoutWrap>
                  <button className="log-in-out-btn-header">
                    <img className="user-image" src="/icons/user.svg" alt="" />
                    <div>Sair</div>
                  </button>
                </LogoutWrap>
              </div>
            </div>
          </div>
          <Menu open={openMenu} onClose={() => setOpenMenu(true)}>
            <div className="buttons-wrapper">
              <img onClick={() => setOpenMenu(false)} src="/icons/close-nav.svg" alt="" className="arrow-nav pointer" />
              <div className="main-nav">
                <div className="navigation-cont">
                  <div className="titel_box">
                    <NavLink to="/">
                      <img src="/icons/logo.svg" alt="" />
                    </NavLink>
                  </div>
                  <div className="border-box"></div>
                  <div className="navigation-menu-cont">
                    <NavLink activeClassName="navigation-menu-box-active" to="/bi" className="navigation-menu-box">
                      <img
                        className="navigation-image"
                        src="/icons/dashboard.svg"
                        width="25px;"
                        alt=""
                      />
                      <div className="navigation-menu-box-text">Dashboard</div>
                    </NavLink>
                    <NavLink activeClassName="navigation-menu-box-active" to="/gestao" className="navigation-menu-box">
                      <img
                        className="navigation-image"
                        src="/icons/gestaodoinventario.svg"
                        width="25px;"
                        alt=""
                      />
                      <div className="navigation-menu-box-text">
                        Gestão do Inventário
                      </div>
                    </NavLink>
                    <NavLink activeClassName="navigation-menu-box-active" to="/comparador" className="navigation-menu-box">
                      <img
                        className="navigation-image"
                        src="/icons/comparadordeprecos.svg"
                        width="25px;"
                        alt=""
                      />
                      <div className="navigation-menu-box-text">
                        Comparador de Preços
                      </div>
                    </NavLink>
                    <NavLink activeClassName="navigation-menu-box-active" to="/acompanhamento" className="navigation-menu-box">
                      <img
                        className="navigation-image"
                        src="/icons/acompanhamentodepedidos.svg"
                        width="25px;"
                        alt=""
                      />
                      <div className="navigation-menu-box-text">
                        Acompanhamento de Pedidos
                      </div>
                    </NavLink>
                    <NavLink activeClassName="navigation-menu-box-active" to="/billing" className="navigation-menu-box">
                      <img
                        className="navigation-image"
                        src="/icons/billing-icon.svg"
                        width=""
                        alt=""
                      />
                      <div className="navigation-menu-box-text">
                        Billing Management
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </Menu>
          {modal && (
            <UpdateProfile modal={modal} onModalClose={onModalClose} setModal={setModal} />
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default withRouter(Header);
