import React, { useState, useEffect } from "react";
import { getInvoice } from "../../../actions/billings";
import { Modal } from "../../ui/modal/Modal";
import Preloader from "../../ui/preloader/Preloader";
import { toStringConverter } from "../tables/gestao/UpdateGestao";

interface Props {
    invoiceRef: string;
    setOpenInvoice: (openInvoice: boolean) => void;
    openInvoice: boolean;
}

export const Invoices: React.FC<Props> = ({ invoiceRef, setOpenInvoice, openInvoice }) => {
    const [invoice, setInvoice] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        async function initializeState() {
            try {
                setInvoice(await getInvoice(invoiceRef));
            } catch (error) {
                console.error(error);
            }

            setLoading(false);
        }
        initializeState();
        // eslint-disable-next-line
    }, []);

    const onModalClose = (e: React.MouseEvent) => {
        e.stopPropagation();
        setOpenInvoice(false);
    };

    return loading ? (
        <Preloader />
    ) : (
        <>
            <Modal onModalClose={onModalClose} isOpened={openInvoice}>
                <div className="billing-modal-cont">
                    <div>
                        <div className="table-modal-title-box m0">
                            <div className="mapping-modal-title-text">Invoice #{invoice.bill_ref}</div>
                            {/* <div className="billing-modal-text red pointer underline">Visualizar invoice oficial</div> */}
                        </div>
                        <form className="table-modal-form-cont">
                            <div className="table-modal-border"></div>
                            <div className="billing-modal-invoice-cont">
                                <div className="billing-modal-invoice-box">
                                    <div className="billing-modal-text bold pr1">Produtor:</div>
                                    <div className="billing-modal-text">{invoice.partner_name}</div>
                                </div>
                                <div className="billing-modal-invoice-box">
                                    <div className="billing-modal-text bold pr1">Total da invoice:</div>
                                    <div className="billing-modal-text">{toStringConverter(invoice.total_amount)} €</div>
                                </div>
                                <div className="billing-modal-invoice-box">
                                    <div className="billing-modal-text bold pr1">Data:</div>
                                    <div className="billing-modal-text">{invoice.invoice_date}</div>
                                </div>
                            </div>
                            <table className="mapping-modal-table">
                                <thead>
                                    <tr className="mapping-modal-results-title">
                                        <th className="billing-modal-medium">SKU</th>
                                        <th className="billing-modal-large">Name</th>
                                        <th className="billing-modal-medium justify-center">Qtd</th>
                                        <th className="billing-modal-medium justify-center">Preço</th>
                                        <th className="billing-modal-medium justify-center">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoice.bill_line_ids.map((item: any, index: number) => (
                                        <React.Fragment key={index}>
                                            <tr className="billing-modal-result-box" >
                                                <td className="billing-modal-medium">
                                                    <div className="billing-text">{item.product_sku}</div>
                                                </td>
                                                <td className="billing-modal-large justify-start">
                                                    <div className="billing-text text-start">{item.product_description}</div>
                                                </td>
                                                <td className="billing-modal-medium justify-center">
                                                    <div className="billing-text">{item.quantity}</div>
                                                </td>
                                                <td className="billing-modal-medium justify-center">
                                                    <div className="billing-text">{toStringConverter(item.price_unit)}€</div>
                                                </td>
                                                <td className="billing-modal-medium justify-center">
                                                    <div className="billing-text">{toStringConverter(item.subtotal)}€</div>
                                                </td>
                                            </tr>
                                            <tr className="border-bottom"></tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                            <div className="billing-modal-invoice-box pt3">
                                <div className="billing-modal-text bold pr1">Total da invoice:</div>
                                <div className="billing-modal-text">{toStringConverter(invoice.total_amount)} €</div>
                            </div>
                            <div className="table-modal-border"></div>
                            <div className="table-modal-form-button-box width100 justify-between">
                                {/* <div className="billing-modal-text underline initial pointer">Ir para Shipment</div> */}
                                <div></div>
                                <button className="table-modal-form-button" onClick={() => setOpenInvoice(false)}>
                                    fechar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    );
}
