import React, { useContext, useEffect, useState } from "react";
import "./gestao.css";
import "../tables.css";
import * as _ from "lodash";
import { Modal } from "../../../ui/modal/Modal";
import {
	updateProduct,
	getPricingBreakdownData
} from "../../../../actions/marketplace";
import { TooltipText } from "./Tooltip";
import { UserContext } from "../../../context";
import { toast } from "react-toastify";
import { IMarketplace } from "../../../../interfaces";
import Preloader from "../../../ui/preloader/Preloader";
import { TooltipInfoText } from "../../../ui/tooltip/TooltipInfoText";
import { getVendorsActive } from "../../../../actions/vendor";

interface Props {
	modal: boolean;
	setModal: (modal: boolean) => void;
	product: any;
	onUpdateCallback: (data: any) => void;
	productMarketplace: IMarketplace;
	vendors?: any;
}

interface TPriceBreakdown {
	vendorPrice: number;
	iva: number;
	iec: number;
	ivaValue: number;
	deliveryPrice: number;
	price: number;
	vinuusPrice: number;
	vinuusRate: number;
	vendorRate: number;
	basePrice: number;
	services: number;
	vinuusMargin: number;
}

export function getPrice(price: string | number) {
	if (typeof price === "string") {
		return parseFloat(price.replace(",", "."));
	} else {
		return price;
	}
}

export function toStringConverter(num: number) {
	return num?.toLocaleString("de-DE", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
}

export function convertPrice(price: any) {
	return parseFloat(String(price).replace(",", ".")).toLocaleString("de-DE", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
}

export function comparePriceCount(discount: number, price: string) {
	return toStringConverter(_.round(getPrice(price) + (getPrice(price) * (discount / 100)), 2));
}

export function getFreightPrice(weight: number, country: string, arr: any) {
	const freight = arr?.find((freight: any) => freight.country === country)?.mapping?.sort((a: any, b: any) => a.weight - b.weight);
	return freight?.find((x: any) => weight <= Number(x.weight) && Number(x.weight) >= Math.round(weight))?.price;
}

export function getTaxValueByCountry(tax: string, arr: any, country: string) {
	return arr?.find((x: any) => x.name === country)?.[tax] || 0;
}

export function getIvaPrice(price: string, arr: any, country: string) {
	const obj = arr?.find((x: any) => x.name === country);
	return obj === undefined ? 0 : (obj.vat * getPrice(price)) / 100;
}

export function getPvpPrice(price: string, arr: any, country: string, deliveryPrice = 0) {
	const inputPrice = price === "" ? 0 : getPrice(price);
	return inputPrice - getIvaPrice(price, arr, country) - getTaxValueByCountry("iec", arr, country) - deliveryPrice;
}

export function getVendorPrice(price: string, arr: any, country: string, deliveryPrice = 0) {
	const inputPrice = price === "" ? 0 : getPrice(price);
	return (inputPrice - getTaxValueByCountry("iec", arr, country) - deliveryPrice) / ((1 + parseInt(getTaxValueByCountry('vat', arr, country)) / 100) * 2);
}

export function getBasePrice(price: string, arr: any, country: string, deliveryPrice = 0) {
	const inputPrice = price === "" ? 0 : getPrice(price);
	return (inputPrice - getTaxValueByCountry("iec", arr, country) - deliveryPrice) / (1 + parseInt(getTaxValueByCountry('vat', arr, country)) / 100);
}

export function calculatePriceBreakdown(
	vendorPrice: number,
	vendorRate: number,
	deliveryPrice: number,
	iec: number,
	iva: number) {

	vendorPrice = vendorPrice || 0;
	vendorRate = vendorRate || 50;
	deliveryPrice = 0;
	iec = iec || 0;
	iva = iva || 0;

	vendorPrice = Number(vendorPrice.toFixed(2));
	const basePrice = Number((vendorPrice / vendorRate * 100).toFixed(2));
	const vinuusRate = 100 - vendorRate;
	const ivaValue = Number((basePrice / 100 * iva).toFixed(2));
	const vinuusPrice = Number((basePrice / 100 * vinuusRate).toFixed(2));
	const price = Number((vendorPrice + vinuusPrice + deliveryPrice + ivaValue + iec).toFixed(2));
	const services = Number((vinuusPrice * 0.9).toFixed(2));
	const vinuusMargin = Number((vinuusPrice - services).toFixed(2));

	return {
		vendorPrice,
		iva,
		iec,
		ivaValue,
		deliveryPrice,
		price,
		vinuusPrice,
		vinuusRate,
		vendorRate,
		basePrice,
		services,
		vinuusMargin
	} as TPriceBreakdown;
}

export function calculateReversePriceBreakdown(
	price: number,
	vendorRate: number,
	deliveryPrice: number,
	iec: number,
	iva: number,
	vendorPrice?: number) {

	price = price || 0;
	vendorRate = vendorRate || 50;
	deliveryPrice = 0;
	iec = iec || 0;
	iva = iva || 0;

	price = Number(price.toFixed(2));
	const basePrice = Number(((price - deliveryPrice - iec) / ((iva + 100) / 100)).toFixed(2));
	const vinuusRate = 100 - vendorRate;
	const ivaValue = Number((basePrice / 100 * iva).toFixed(2));
	// const vendorPrice = Number((basePrice / 100 * vendorRate).toFixed(2));
	let vinuusPrice = Number((basePrice / 100 * vinuusRate).toFixed(2));
	if (vendorPrice) {
		vinuusPrice = basePrice - vendorPrice;
	} else {
		vendorPrice = Number((basePrice / 100 * vendorRate).toFixed(2));
	}
	const services = Number((vinuusPrice * 0.9).toFixed(2));
	const vinuusMargin = Number((vinuusPrice - services).toFixed(2));

	return {
		vendorPrice,
		iva,
		iec,
		ivaValue,
		deliveryPrice,
		price,
		vinuusPrice,
		vinuusRate,
		vendorRate,
		basePrice,
		services,
		vinuusMargin
	} as TPriceBreakdown;
}

export const UpdateGestao: React.FC<Props> = ({
	modal,
	setModal,
	product,
	productMarketplace,
	vendors
}) => {
	const { user } = useContext(UserContext) as any;
	const productInfo = product.marketplaces[productMarketplace.zeoosName] || {};
	const compareAtPriceInit = productInfo.compareAtPrice != null ? productInfo.compareAtPrice : ((parseFloat(productInfo.price) / 100) * 5 + parseFloat(productInfo.price)).toFixed(2);
	const [loading, setLoading] = useState<boolean>(true);
	const [quantity, setQuantity] = useState<any>(productInfo.stock || 0);
	const [newPrice, setNewPrice] = useState<boolean>(false);
	const [breakdownStandard, setBreakdownStandard] = useState<TPriceBreakdown>({} as TPriceBreakdown);
	const [breakdownMain, setBreakdownMain] = useState<TPriceBreakdown>({} as TPriceBreakdown);
	const [stateButton, setStateButton] = useState<boolean>(true);
	const [initialMain, setInitialMain] = useState<number>(0);
	const [proposePVP, setProposePVP] = useState<boolean>(false);
	const [breakdownProposed, setBreakdownProposed] = useState<TPriceBreakdown>({} as TPriceBreakdown);

	useEffect(() => {
		async function initializeState() {
			const pricingData = await getPricingBreakdownData({
				sku: product.sku,
				zeoosName: productMarketplace.zeoosName,
				seller: product.seller_id
			});

			const vendor = (vendors || await getVendorsActive()).find((vendor: any) => vendor.id === product.seller_id) as any;
			let vendorPrice = getPrice(product.marketplaces[productMarketplace.zeoosName].vendorPriceStandard || product.marketplaces[productMarketplace.zeoosName].vendorPrice);

			setBreakdownStandard(calculatePriceBreakdown(
				vendorPrice,
				vendor?.rate || 50,
				Number(pricingData.deliveryPrice),
				pricingData.iec,
				pricingData.iva
			));

			if (product.marketplaces[productMarketplace.zeoosName].priceStandard &&
				product.marketplaces[productMarketplace.zeoosName].priceStandard !== product.marketplaces[productMarketplace.zeoosName].price) {

				setBreakdownMain(calculateReversePriceBreakdown(
					getPrice(product.marketplaces[productMarketplace.zeoosName].price),
					vendor?.rate || 50,
					Number(pricingData.deliveryPrice),
					pricingData.iec,
					pricingData.iva,
					product.marketplaces[productMarketplace.zeoosName].vendorPriceStandard
				));

				setInitialMain(getPrice(product.marketplaces[productMarketplace.zeoosName].price));

				setNewPrice(true);
			}

			setLoading(false);
		}

		initializeState();
		// eslint-disable-next-line
	}, [product.sku, productMarketplace.zeoosName, product.weight, productMarketplace.country]);

	const onSubmitHandler = async (e: React.MouseEvent) => {
		setStateButton(true);
		e.preventDefault();

		const toUpdate = {
			id: productInfo.id,
			extraId: productInfo.extraId,
			priceStandard: Number(breakdownStandard.price),
			price: Number(breakdownProposed.price || breakdownMain.price || breakdownStandard.price),
			proposedPrice: breakdownProposed.price,
			stock: parseInt(quantity),
			compareAtPrice: compareAtPriceInit,
			sku: product.sku,
			ean: product.barcode,
			zeoosName: productMarketplace?.zeoosName,
			vendorPriceStandard: Number(breakdownStandard.vendorPrice),
			vendorPrice: Number(breakdownProposed.vendorPrice || breakdownMain.vendorPrice || breakdownStandard.vendorPrice)
		};

		await updateProduct(
			toUpdate,
			productMarketplace.zeoosName as string,
		);

		toast.success("Your request is under analysis and we will contact you shortly");

		setModal(false);
		setStateButton(false);
	};

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	const proposeNewPrice = async (e: React.MouseEvent) => {
		e.preventDefault();

		(document.querySelector("#proposedPriceValue") as HTMLInputElement)
			.value = breakdownMain.price ?
				breakdownMain.price.toString() :
				breakdownStandard.price.toString();

		setBreakdownProposed(breakdownMain.price ? breakdownMain : breakdownStandard);

		setProposePVP(true);
	};

	const proposeRemoveMain = async (e: React.MouseEvent) => {
		e.preventDefault();

		toast.info("To remove Main Pricing, propose Standard Pricing",
			{ autoClose: 4000, position: 'top-center' }
		);

		(document.querySelector("#proposedPriceValue") as HTMLInputElement)
			.value = breakdownStandard.price.toString();

		(document.querySelector("#mainPvpPrice") as HTMLElement)
			.classList.add('removed');

		setBreakdownProposed(breakdownStandard);

		setStateButton(false);
	};

	return loading ? (
		<Preloader />
	) : (
		<Modal onModalClose={onModalClose} isOpened={modal} bodyClassName="priceBreakdown">
			<div className="table-modal-cont">
				<div className="table-modal-title-box">
					<div>
						<div className="table-modal-title-pricing">
							{proposePVP ? 'Propose a New PVP' : 'Pricing Breakdown'}
						</div>
						<div className="table-modal-title-title">
							<TooltipText text={product?.name} items={7} separator={" "} /> ({product?.sku}) - {productMarketplace.zeoosName}
						</div>
					</div>
					<div className="table-modal-form-box-stock">
						<label className="table-modal-form-title-stock">Stock:</label>
						{user.role === "ADMIN" ? (
							<input
								className="table-modal-form-value table-modal-border-value"
								onChange={(e) => { setQuantity(e.target.value); setStateButton(false); }}
								value={quantity}
							/>
						) : (
							<div className="table-modal-form-value" style={{ color: "black" }}>{quantity}</div>
						)}
					</div>
				</div>
				<div className="table-modal-border"></div>
				<form className="table-modal-form-cont">
					<div className="table-modal-form-columns">
						<div className="table-modal-form-main-column">
							<div className="table-modal-form-box">
								<label className="table-modal-form-title">
								</label>
								<div className={`width50 ${newPrice ? 'on' : 'off'} in-row align-center bold`}>
									Standard pricing
								</div>
								<div className="newPriceButton in-row align-center bold">
									{newPrice === false ?
										(
											<button className={`table-modal-button-newprice ${proposePVP ? 'proposeON' : ''}`}
												onClick={proposeNewPrice}>
												Propose a New PVP
											</button>
										) :
										<>Main Pricing</>}
								</div>
							</div>
							<div className="table-modal-form-green">
								<div className="table-modal-form-box">
									<label className="table-modal-form-title bold">PVP VISÃO POR:</label>
									<div className={`width50 ${newPrice ? 'on' : 'off'} in-row align-center`}>
										<div className="table-modal-form-value">
											{toStringConverter(Number(breakdownStandard.price))} €
										</div>
									</div>
									<div className={`dif ${newPrice ? 'on' : 'off'}`}>
										{breakdownMain.price - breakdownStandard.price >= 0 ? '+' : ''}
										{toStringConverter(breakdownMain.price - breakdownStandard.price)} €
									</div>
									<div className={`newPrice ${newPrice ? 'on' : 'off'} in-row align-center`}>
										<div className="table-modal-form-value" id="mainPvpPrice">
											{
												`${toStringConverter(breakdownMain.price)} €`
											}
										</div>
									</div>
								</div>
							</div>
							<div className={`table-modal-form-box ${proposePVP ? 'proposeON' : ''}`}>
								<label className="table-modal-form-title">
									IVA ({breakdownStandard.iva}%):
								</label>
								<div className={`width50 ${newPrice ? 'on' : 'off'} in-row align-center`}>
									<div className="table-modal-form-value">
										{
											breakdownStandard.ivaValue === 0 ?
												<span className="na">N/A</span> :
												`${toStringConverter(breakdownStandard.ivaValue)} €`
										}
									</div>
								</div>
								<div className={`dif ${newPrice ? 'on' : 'off'}`}>
									{
										breakdownMain.ivaValue === 0 ?
											<span className="na">N/A</span> :
											`${breakdownMain.ivaValue - breakdownStandard.ivaValue >= 0 ? '+' : ''}
											${toStringConverter(breakdownMain.ivaValue - breakdownStandard.ivaValue)} €`
									}

								</div>
								<div className={`newPrice ${newPrice ? 'on' : 'off'} in-row align-center`}>
									<div className="table-modal-form-value">
										{
											breakdownMain.ivaValue === 0 ?
												<span className="na">N/A</span> :
												`${toStringConverter(breakdownMain.ivaValue)} €`
										}
									</div>
								</div>
							</div>
							<div className={`table-modal-form-box ${proposePVP ? 'proposeON' : ''}`}>
								<label className="table-modal-form-title">IEC:</label>
								<div className={`width50 ${newPrice ? 'on' : 'off'} in-row align-center`}>
									<div className="table-modal-form-value">
										{
											breakdownStandard.iec === 0 ?
												<span className="na">N/A</span> :
												`${toStringConverter(breakdownStandard.iec)} €`
										}
									</div>
								</div>
								<div className={`esp dif ${newPrice ? 'on' : 'off'}`}>
									NaN €
								</div>
								<div className={`newPrice ${newPrice ? 'on' : 'off'} in-row align-center`}>
									<div className="table-modal-form-value">
										{
											breakdownMain.iec === 0 ?
												<span className="na">N/A</span> :
												`${toStringConverter(breakdownMain.iec)} €`
										}
									</div>
								</div>
							</div>
							<div className={`table-modal-form-box ${proposePVP ? 'proposeON' : ''}`}>
								<label className="table-modal-form-title">Frete:</label>
								<div className={`width50 ${newPrice ? 'on' : 'off'} in-row align-center`}>
									<div className="table-modal-form-value">
										{
											breakdownStandard.deliveryPrice === 0 ?
												<span className="na">N/A</span> :
												`${toStringConverter(breakdownStandard.deliveryPrice)} €`
										}
									</div>
								</div>
								<div className={`esp dif ${newPrice ? 'on' : 'off'}`}>
									NaN €
								</div>
								<div className={`newPrice ${newPrice ? 'on' : 'off'} in-row align-center`}>
									<div className="table-modal-form-value">
										{
											breakdownMain.deliveryPrice === 0 ?
												<span className="na">N/A</span> :
												`${toStringConverter(breakdownMain.deliveryPrice)} €`
										}
									</div>
								</div>
							</div>
							<div className={`table-modal-form-box ${proposePVP ? 'proposeON' : ''}`}>
								<label className="table-modal-form-title">PVP Base:</label>
								<div className={`width50 ${newPrice ? 'on' : 'off'} in-row align-center`}>
									<div className="table-modal-form-value">
										{toStringConverter(breakdownStandard.basePrice)} €
									</div>
								</div>
								<div className={`dif ${newPrice ? 'on' : 'off'}`}>
									{breakdownMain.basePrice - breakdownStandard.basePrice >= 0 ? '+' : ''}
									{toStringConverter(breakdownMain.basePrice - breakdownStandard.basePrice)} €
								</div>
								<div className={`newPrice ${newPrice ? 'on' : 'off'} in-row align-center`}>
									<div className="table-modal-form-value">
										{toStringConverter(breakdownMain.basePrice)} €
									</div>
								</div>
							</div>
							<div className={`table-modal-form-box ${proposePVP ? 'proposeON' : ''}`}>
								<label className="table-modal-form-title">
									<TooltipInfoText text={
										<div className="pricingTooltop">
											<div className="service">
												<strong>Services:</strong>
												<span>
													{toStringConverter(
														newPrice ?
															breakdownMain.services :
															breakdownStandard.services
													)} €
												</span>
											</div>
											<ul>
												<li>Marketing and Advertisement</li>
												<li>Marketplace Platforms Comissions</li>
												<li>Payment Services Fees</li>
												<li>Catalogue Data Management</li>
												<li>Pricing Management</li>
												<li>Warehousing</li>
												<li>Order management</li>
												<li>Picking</li>
												<li>Packing</li>
												<li>Invoicing</li>
												<li>Shipping & Order Monitoring</li>
												<li>Customer Services</li>
											</ul>
											<div className="margin">
												<strong>Vinuus Margin:</strong>
												<span>
													{toStringConverter(
														newPrice ?
															breakdownMain.vinuusMargin :
															breakdownStandard.vinuusMargin
													)} €
												</span>
											</div>
										</div>
									}
									>
										<strong>Services</strong> + Vinuus Margin:
									</TooltipInfoText>
									{/* Vinuus Price ({breakdownStandard.vinuusRate}%): */}
								</label>
								<div className={`width50 ${newPrice ? 'on' : 'off'} in-row align-center`}>
									<div className="table-modal-form-value">
										{toStringConverter(breakdownStandard.vinuusPrice)} €
									</div>
								</div>
								<div className={`dif ${newPrice ? 'on' : 'off'}`}>
									{breakdownMain.vinuusPrice - breakdownStandard.vinuusPrice >= 0 ? '+' : ''}
									{toStringConverter(breakdownMain.vinuusPrice - breakdownStandard.vinuusPrice)} €
								</div>
								<div className={`newPrice ${newPrice ? 'on' : 'off'} in-row align-center`}>
									<div className="table-modal-form-value">
										{toStringConverter(breakdownMain.vinuusPrice)} €
									</div>
								</div>
							</div>
							<div className={`table-modal-form-box ${proposePVP ? 'proposeON' : ''}`}>
								<label className="table-modal-form-title bold">
									Vendor Price:
								</label>
								<div className={`width50 ${newPrice ? 'on' : 'off'} in-row align-center`}>
									<div className="table-modal-form-value bold">
										{toStringConverter(breakdownStandard.vendorPrice)} €
									</div>
								</div>
								<div className={`dif ${newPrice ? 'on' : 'off'}`}>
									{breakdownMain.vendorPrice - breakdownStandard.vendorPrice >= 0 ? '+' : ''}
									{toStringConverter(breakdownMain.vendorPrice - breakdownStandard.vendorPrice)} €
								</div>
								<div className={`newPrice ${newPrice ? 'on' : 'off'} in-row align-center`} style={{ position: 'relative' }}>
									<div className="table-modal-form-value bold">
										{toStringConverter(breakdownMain.vendorPrice)} €
									</div>
								</div>
							</div>
							{newPrice !== false ?
								<div className={`table-modal-form-box remove ${proposePVP ? 'proposeON' : ''}`}>
									{
										<button className="table-modal-button-newprice"
											onClick={proposeNewPrice}>
											Propose a New PVP
										</button>
									}
								</div> : ""
							}
							<div className={`table-modal-form-box suggest ${proposePVP ? '' : 'proposeON'}`}>
								<label className="table-modal-form-title bold">
									Suggested PVP:
								</label>
								<div className={`width50 ${newPrice ? 'on' : 'off'} in-row align-center`}>

									<input type="number"
										step=".01"
										className="table-modal-form-value table-modal-border-value"
										id="proposedPriceValue"
										name="proposedprice"
										onChange={async (e) => {
											(document.querySelector("#mainPvpPrice") as HTMLElement)
												.classList.remove('removed');

											const newBreakdown = calculateReversePriceBreakdown(
												Number(e.target.value),
												breakdownStandard.vendorRate,
												breakdownStandard.deliveryPrice,
												breakdownStandard.iec,
												breakdownStandard.iva,
												breakdownStandard.vendorPrice
											);

											if (newBreakdown.vinuusPrice > 0) {
												setBreakdownProposed(newBreakdown);
												setStateButton(
													e.target.value === initialMain.toString()
												);
											}
										}}
									/>

								</div>
								<div className={`newPrice ${newPrice ? 'on' : 'off'} in-row align-center`}>
									or
									<button className="table-modal-button-newprice"
										title="To remove Main Pricing, propose Standard Pricing"
										onClick={(proposeRemoveMain)}>
										Remove Main Pricing
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="table-modal-border"></div>
					<div className="table-modal-form-button-box">
						<button onClick={onModalClose} className="table-modal-form-button-cancel">
							Cancel
						</button>
						<button
							className="table-modal-form-button"
							type="submit"
							disabled={stateButton}
							onClick={onSubmitHandler}>
							update
						</button>
					</div>
				</form>
			</div >
		</Modal >
	);
};
