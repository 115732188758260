import React, { useEffect, useMemo, useState } from "react";
import { IPaginatedRequest, IPaginatedResponse } from "../../../interfaces";
import "./pagination.css";

interface Props {
  data: any;
  refetch: (config: IPaginatedRequest) => Promise<IPaginatedResponse<any>>;
}

export const limitArr = [
  { text: "Show 50", limit: 50 },
  { text: "Show 100", limit: 100 },
  { text: "Show 200", limit: 200 },
];

const Paginator: React.FC<Props> = ({ data, refetch }) => {
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(50);

  useEffect(() => {
    refetch({ page, perPage });
    // eslint-disable-next-line
  }, [page, perPage]);

  const handleChangePage = (e: React.MouseEvent, newPage: number) => {
    e.preventDefault();
    setPage(newPage);
  };

  const prevPage = (e: React.MouseEvent) => {
    e.preventDefault();

    if (page === 1) {
      return setPage(1);
    }

    setPage((prev) => prev - 1);
  };

  const nextPage = (e: React.MouseEvent) => {
    e.preventDefault();
    setPage((prev) => prev + 1);
  };

  const pages = useMemo(() => {
    const pageNumbers = [];

    for (let i = 1; i <= data.lastPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  }, [data.lastPage]);

  const displayPages = useMemo(() => {
    if (pages.length <= 3) {
      return pages;
    }

    if (page === 1) {
      return pages.slice(page - 1, page + 2);
    }

    if (page === data.lastPage) {
      return pages.slice(data.lastPage - 3, data.lastPage);
    }

    return pages.slice(page - 2, page + 1);
  }, [page, pages, data.lastPage]);

  if (!displayPages.length) {
    return <></>;
  }

  return (
    <>
      <select
        className="main-select-bi"
        value={`Show ${perPage}`}
        onChange={(e) =>
          setPerPage(
            limitArr.find((item) => item.text === e.target.value)!.limit
          )
        }
      >
        {limitArr.map((item, index: number) => (
          <option key={index}>{item.text}</option>
        ))}
      </select>

      <div className="pagination-container">
        <div
          className={`pagination-admin-button ${page === 1 ? "admin-button-disabled" : ""
            }`}
          onClick={prevPage}
        >
          <img src="/icons/less-portal.svg" width="10px;" alt="" />
        </div>

        {displayPages.map((p: number) => {
          return (
            <div
              key={p}
              onClick={(e) => handleChangePage(e, p)}
              className={`pagination-admin-button ${page === p ? "current-admin-button" : "prev-next-color-button"
                }`}
            >
              {p}
            </div>
          );
        })}

        <div
          className={`pagination-admin-button ${page === data.lastPage
            ? "admin-button-disabled"
            : "prev-next-color-button"
            }`}
          onClick={nextPage}
        >
          <img src="/icons/more-portal.svg" width="10px;" alt="" />
        </div>
      </div>
    </>
  );
};

export default Paginator;
