import React, { useContext } from 'react';
import { removeToken } from '../../../actions/token';
import { UserContext } from '../../context';

const LogoutWrap: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const { user, setUser } = useContext(UserContext);

    return !user ? <></> : (
        <div onClick={(e) => {
            e.preventDefault();
            removeToken();
            setUser(null);
        }}>
            {children}
        </div>
    );
};

export default LogoutWrap;
