import React, { useEffect, useState } from "react";
import { getBillingPrice } from "../../../../actions/billings";
import { Modal } from "../../../ui/modal/Modal";
import Preloader from "../../../ui/preloader/Preloader";
import { toStringConverter } from "../../tables/gestao/UpdateGestao";

interface Props {
    item: any;
    bill: any;
}

export const Preco: React.FC<Props> = ({ item, bill }) => {
    const [modal, setModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [billingPrice, setBillingPrice] = useState<any>();

    useEffect(() => {
        async function initializeState() {
            try {
                setBillingPrice(await getBillingPrice(item.bill_id));
            } catch (error) {
                console.error(error);
            }

            setLoading(false);
        }

        initializeState();
        // eslint-disable-next-line
    }, []);

    const onModalClose = (e: React.MouseEvent) => {
        e.stopPropagation();
        setModal(false);
    };

    return (
        <>
            <div onClick={() => {
                setModal(true);
            }} className="billing-text bold underline pointer">{toStringConverter(bill.subtotal)} €</div>
            {modal && (
                loading ? (
                    <Preloader />
                ) : (
                    <Modal onModalClose={onModalClose} isOpened={modal}>
                        <div className="mapping-modal-cont">
                            <div>
                                <div className="table-modal-title-box m0">
                                    <div className="mapping-modal-title-text">Pricing Breakdown</div>
                                </div>
                                <div className="billing-modal-title-cont">
                                    <div className="billing-modal-text bold">{item.bill_id}</div>
                                    <div className="billing-modal-text">{item.date}</div>
                                </div>
                                <form className="table-modal-form-cont">
                                    <div className="table-modal-border"></div>
                                    <div className="pt2 pb2">
                                        <div className="billing-modal-box billing-modal-text greybg">
                                            <div className="width100 bold">PVP Visão Por:</div>
                                            <div className="billing-input-box">
                                                <div>{toStringConverter(billingPrice.pvp_visao_por)}€</div>
                                            </div>
                                        </div>
                                        <div className="billing-modal-box billing-modal-text">
                                            <div className="width100">IVA:</div>
                                            <div className="billing-input-box">
                                                <div>
                                                    {toStringConverter(billingPrice.iva)} €
                                                </div>
                                            </div>
                                        </div>
                                        <div className="billing-modal-box billing-modal-text">
                                            <div className="width100">IEC:</div>
                                            <div className="billing-input-box">
                                                <div>
                                                    {toStringConverter(billingPrice.iec)} €
                                                </div>
                                            </div>
                                        </div>
                                        <div className="billing-modal-box billing-modal-text">
                                            <div className="width100">Frete:</div>
                                            <div className="billing-input-box">
                                                < div>{toStringConverter(billingPrice.frete)} €</div>
                                            </div>
                                        </div>
                                        <div className="billing-modal-box billing-modal-text">
                                            <div className="width100">PVP Base:</div>
                                            <div className="billing-input-box">
                                                <div>{toStringConverter(billingPrice.pvp_base)} €</div>
                                            </div>
                                        </div>
                                        <div className="billing-modal-box billing-modal-text">
                                            <div className="width100">Vinuus Rate:</div>
                                            <div className="billing-input-box">
                                                <div>{toStringConverter(billingPrice.vinuus_rate)}€</div>
                                                <div className="billing-modal-subtext">(50%)</div>
                                            </div>
                                        </div>
                                        <div className="billing-modal-box billing-modal-text">
                                            <div className="width100 bold">Preço Compra (50%):</div>
                                            <div className="billing-input-box">
                                                <div>{toStringConverter(billingPrice.preco_compra)}€</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-modal-border"></div>
                                    <div className="table-modal-form-button-box width100">
                                        <button className="table-modal-form-button" onClick={() => setModal(false)}>
                                            fechar
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal>
                ))}
        </>
    );
}
