import React from "react";
import { B2CMarketplace } from "./B2CMarketplace";
import { ICombinedOrders, ICombinedOrder, ISaleLineIds } from "../../../interfaces";
import { FlashSales } from "./FlashSales";

interface Props {
	orders: ICombinedOrders;
	section: string;
	country: string;
	category: string;
	marketplaceMarket: string;
	marketplaceAccount: string;
}

export const Cards: React.FC<Props> = ({
	orders,
	section,
	country,
	category,
	marketplaceMarket,
	marketplaceAccount
}) => {
	function addTotalQty(array: ICombinedOrder[]) {
		array.forEach(
			(x: any) =>
			(x.totalQuantity = x.sale_line_ids.reduce((acc: number, cv: ISaleLineIds) => {
				return acc + cv.quantity;
			}, 0))
		);
		return array;
	}
	addTotalQty(orders.b2c);
	addTotalQty(orders.b2b);

	function avgOrderCounter(array: ICombinedOrder[]) {
		if (array.length === 0) return 0;
		return (
			array.reduce((prev: number, next: ICombinedOrder) => prev + next.sale_amount, 0) /
			array.length
		).toLocaleString("de-DE", {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});
	}

	function grossValueCounter(array: ICombinedOrder[]) {
		if (array.length === 0) return 0;
		return array
			.reduce((prev: number, next: ICombinedOrder) => prev + next.sale_amount, 0)
			.toLocaleString("de-DE", {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});
	}

	const renderSection = (section: string) => {
		switch (section) {
			case 'B2C':
				return <B2CMarketplace
					orders={orders}
					avgOrderCounter={avgOrderCounter}
					grossValueCounter={grossValueCounter}
					category={category}
					countryb2c={country}
					marketplaceMarket={marketplaceMarket}
					marketplaceAccount={marketplaceAccount}
				/>;
			case "FlashSales":
				return (
					<FlashSales
						orders={orders}
						avgOrderCounter={avgOrderCounter}
						grossValueCounter={grossValueCounter}
						countryb2b={country}
						category={category}
					/>
				);
			default:
				return;
		}
	}

	return (
		<div className="bi__cards">
			{renderSection(section)}
		</div>
	);
};
