import React, { useEffect, useState } from "react";
import "./acompanhamento.css";
import "../tables.css";
import * as _ from "lodash";
import { IOrdersAcompB2 } from "../../../../interfaces";

interface Props {
	orders: any;
	filteredOrdersCallback: (data: IOrdersAcompB2[]) => void;
}

export const Selects: React.FC<Props> = ({
	orders,
	filteredOrdersCallback,
}) => {
	const [status, setStatus] = useState<string>("All Status");
	const [country, setCountry] = useState<string>("All Countries");
	const [channel, setChannel] = useState<string>("All Channels");

	function addb2bMarketplace(array: any) {
		array.forEach((x: any) => (x.sale_marketplace_account = "B2B"));
		return array;
	}

	const ordersCombined = [...addb2bMarketplace(orders.b2b), ...orders.b2c];

	function statusArray(array: any) {
		return [
			"All Statuses",
			..._.uniq(_.map(array, (item: any) => item.sale_lengow_status)).filter(
				(x: any) => x !== "" && x !== false
			),
		];
	}

	function countriesArray(array: any) {
		return [
			"All Countries",
			..._.uniq(_.map(array, (item: any) => item.country)),
		];
	}

	function channelsArray(array: any) {
		return [
			"All Channels",
			..._.uniq(_.map(array, (item: any) => item.sale_marketplace_account)),
		];
	}

	useEffect(() => {
		filteredOrdersCallback(ordersCombined);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const filteredByStatus =
			status === "All Statuses"
				? ordersCombined
				: ordersCombined.filter((x: any) => x.sale_lengow_status === status);
		filteredOrdersCallback(filteredByStatus);
		// eslint-disable-next-line
	}, [status]);

	useEffect(() => {
		const filteredByCountry =
			country === "All Countries"
				? ordersCombined
				: ordersCombined.filter((x: any) => x.country === country);
		filteredOrdersCallback(filteredByCountry);
		// eslint-disable-next-line
	}, [country]);

	useEffect(() => {
		const filteredByChannel =
			channel === "All Channels"
				? ordersCombined
				: ordersCombined.filter(
					(x: any) => x.sale_marketplace_account === channel
				);
		filteredOrdersCallback(filteredByChannel);
		// eslint-disable-next-line
	}, [channel]);

	return (
		<div className="acompan-select-cont">
			<div className="acompan-select-title">Filtrar por:</div>
			<select
				id="acompan-status"
				name="acompan-status"
				required
				className="acompan-select"
				value={status}
				onChange={(e) => {
					e.preventDefault();
					setStatus(e.target.value);
					setCountry("All Countries");
					setChannel("All Channels");
				}}
			>
				{statusArray(ordersCombined).map((item: any, key: number) => (
					<option className="acompan-option" key={key}>
						{item}
					</option>
				))}
			</select>
			<select
				id="acompan-countries"
				name="acompan-countries"
				required
				className="acompan-select"
				value={country}
				onChange={(e) => {
					e.preventDefault();
					setCountry(e.target.value);
					setStatus("All Status");
					setChannel("All Channels");
				}}
			>
				{countriesArray(ordersCombined).map((item: any, key: number) => (
					<option className="acompan-option" key={key}>
						{item}
					</option>
				))}
			</select>
			<select
				id="acompan-channels"
				name="acompan-channels"
				required
				className="acompan-select"
				value={channel}
				onChange={(e) => {
					e.preventDefault();
					setChannel(e.target.value);
					setStatus("All Status");
					setCountry("All Countries");
				}}
			>
				{channelsArray(ordersCombined).map((item: any, key: number) => (
					<option className="acompan-option" key={key}>
						{item}
					</option>
				))}
			</select>
		</div>
	);
};
