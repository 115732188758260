import React, { useMemo, useState } from "react";
import * as _ from "lodash";
import randomColor from "randomcolor";
import { Modal } from "../../ui/modal/Modal";
import { DetailsTable } from "./DetailsTable";
import { GridValueGetterParams } from "@material-ui/data-grid";
import { ICombinedOrder, ICombinedProduct, IObjData } from "../../../interfaces";

const { Bar } = require("react-chartjs-2");

interface Props {
  orders: ICombinedOrder[];
  orders2: any;
  category: string;
}

interface IChart {
  labels: Array<string>;
  data: Array<number>;
  color: Array<string>;
  arr: ICombinedProduct[];
  orderDetailsModal: boolean;
  setModal: (modal: boolean) => void;
  setOrderDetailsModal: (orderDetailsModal: boolean) => void;
  details: ICombinedProduct[];
  setDetails: (details: ICombinedProduct[]) => void;
}

export const Top5Chart: React.FC<Props> = ({ orders, orders2, category }) => {
  const [modal, setModal] = useState<boolean>(false);
  const [orderDetailsModal, setOrderDetailsModal] = useState<boolean>(false);
  const [details, setDetails] = useState<ICombinedProduct[]>([]);

  const getReport = useMemo(() => {
    const products = orders.flatMap((x: ICombinedOrder) => x.sale_line_ids);
    const productGroups = _.groupBy(products, "sku");
    const _labels = Object.keys(productGroups);
    const data = _labels
      .map((x) => {
        return {
          value: productGroups[x].reduce(
            (prev: number, next: any) => prev + next.quantity * next.price,
            0
          ),
          label: orders2.find((order: any) => order.sku === x)?.name,
        };
      })
      .sort((a: IObjData, b: IObjData) => b.value - a.value)
      .slice(0, 5);

    return {
      labels: data.map((x) => x.label),
      data: data.map((x) => x.value),
      arr: products,
    };
    // eslint-disable-next-line
  }, [orders]);

  const getReport2 = useMemo(() => {
    const productGroups = _.groupBy(orders2, "sku");
    const _labels = Object.keys(productGroups);
    const data = _labels
      .map((x) => {
        return {
          value: productGroups[x].reduce(
            (prev: number, next: any) => prev + next.quantity * next.price,
            0
          ),
          label: orders2.find((order: any) => order.sku === x)?.name,
        };
      })
      .sort((a: IObjData, b: IObjData) => b.value - a.value)
      .slice(0, 5);

    return {
      labels: data.map((x) => x.label),
      data: data.map((x) => x.value),
      arr: orders2,
    };
    // eslint-disable-next-line
  }, [orders2]);

  const { labels, data, arr }: any =
    category !== "All Categories" ? getReport2 : getReport;

  const color = useMemo(
    () =>
      randomColor({
        count: labels.length,
        hue: "random",
        format: "rgba",
        alpha: 0.3,
      }),
    // eslint-disable-next-line
    [labels]
  );

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal(false);
  };

  return (
    <div
      className="bi__card"
      onClick={() => {
        setModal(true);
      }}
    >
      <div className="bi__card_title">Top 5 products</div>
      <div className="bi__card_content">
        <Top5ChartComponent
          labels={labels}
          data={data}
          color={color}
          arr={arr}
          orderDetailsModal={orderDetailsModal}
          setModal={setModal}
          setOrderDetailsModal={setOrderDetailsModal}
          details={details}
          setDetails={setDetails}
        />
        {modal && (
          <Modal onModalClose={onModalClose} isOpened={modal}>
            <div className="modal-width">
              <Top5ChartComponent
                labels={labels}
                data={data}
                color={color}
                arr={arr}
                orderDetailsModal={orderDetailsModal}
                setModal={setModal}
                setOrderDetailsModal={setOrderDetailsModal}
                details={details}
                setDetails={setDetails}
              />
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export const Top5ChartComponent: React.FC<IChart> = ({
  labels,
  data,
  color,
  arr,
  orderDetailsModal,
  setModal,
  setOrderDetailsModal,
  details,
  setDetails,
}) => {
  const onClickHandler = (event: any, elements: any) => {
    setModal(false);

    function getOrdersbySKU(array: any, sku: string) {
      return array.filter((x: any) => x.sku === sku);
    }

    if (!elements[0]) {
      return;
    }

    const chart = event.chart;
    const { index } = elements[0];
    const label = chart.config._config.data.labels[index];
    setDetails(getOrdersbySKU(arr, label));
    setOrderDetailsModal(true);
  };

  const onModalClose2 = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOrderDetailsModal(false);
    setModal(true);
  };

  const tooltipLabels = (arr: any) => {
    return arr.map((label: string) => label.split(" ").length > 5
      ? label.split(" ").slice(0, 3).join(" ") + "..."
      : label)
  }

  return (
    <>
      <Bar
        type="bar"
        data={{
          labels: tooltipLabels(labels),
          datasets: [
            {
              label: "Invoiced amount",
              data,
              backgroundColor: color,
            },
          ],
        }}
        height={300}
        width={400}
        options={{
          maintainAspectRatio: false,
          indexAxis: 'y',
          onClick: onClickHandler,
        }}
      />
      {orderDetailsModal && (
        <Modal onModalClose={onModalClose2} isOpened={orderDetailsModal}>
          <DetailsTable orderDetails={details} columns={columnsTop5} />
        </Modal>
      )}
    </>
  );
};

export const columnsTop5 = [
  {
    field: "sku",
    headerName: "SKU",
    width: 150,
    valueGetter: (params: GridValueGetterParams) => `${params.row.sku}`,
  },
  {
    field: "category_id",
    headerName: "Category",
    width: 200,
    valueGetter: (params: GridValueGetterParams) => `${params.row.category_id}`,
  },
  {
    field: "name",
    headerName: "Name",
    width: 300,
    valueGetter: (params: GridValueGetterParams) => `${params.row.name}`,
  },
  {
    field: "price",
    headerName: "Price",
    width: 150,
    valueGetter: (params: GridValueGetterParams) => `${params.row.price}`,
  },
];
