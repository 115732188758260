import React from "react";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface Props {
    data: any;
}

export const Quantidade: React.FC<Props> = ({ data }) => {

    const totalItems = (totalValue: string) => {
        return data.reduce((prev: any, next: any) => prev + next[totalValue], 0);
    }

    const percentage = () => {
        return totalItems("total_number_of_items_sold") * 100 / totalItems("total_number_of_items_purchased");
    }

    return (
        <>
            <div className="billing-graph-box">
                <div className="billing-modal-text bold pb1">Quantidade de Itens</div>
                <div className="billing-quan-box">
                    <div className="width100 justify-center">
                        <div className="billing-circular" style={{ width: 200, height: 200 }}>
                            <CircularProgressbar value={percentage()} />
                            <div className="billing-circular-box">
                                <div className="billing-large-text blue bold">{totalItems("total_number_of_items_sold")}</div>
                                <div className="billing-modal-text grey2">{totalItems("total_number_of_items_purchased") - totalItems("total_number_of_items_sold")}</div>
                            </div>
                        </div>
                    </div>
                    <div className="billing-quan-cont">
                        <div className="billing-perf-total-cont justify-start pb2">
                            <div className="billing-ball bluebg mr4"></div>
                            <div className="billing-text bold grey2">{percentage().toFixed(2)}% Vendidos</div>
                        </div>
                        <div className="billing-perf-total-cont justify-start">
                            <div className="billing-ball semibluebg mr4"></div>
                            <div className="billing-text bold grey2">{(100 - percentage()).toFixed(2)}% Restantes</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
