import axios from "axios";
import { getHeaders } from "./user";

export const getNotifications = async () => {
	const res = await axios.get("/notifications", getHeaders());
	return res.data.notifications;
};

export const readAllNotifcations = async () => {
	await axios.post("/notifications/readAll", {}, getHeaders());
};

export const getAllRequests = async () => {
	const res = await axios.get("/requests/all", getHeaders());
	return res.data.requests;
};

export const getUserRequests = async () => {
	const res = await axios.get("/requests", getHeaders());
	return res.data.requests;
};

export const changeAdminStatus = async (requestId: string, status: string) => {
	await axios.post(`/request/${requestId}/changeStatus`, { status }, getHeaders());
};

export const sendUserRequest = async (body: any) => {
	await axios.post("/request", body, getHeaders());
};

export const cancelUserRequest = async (id: string) => {
	await axios.post(`/request/${id}/cancel`, {}, getHeaders());
};
