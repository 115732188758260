import React, { useState, useContext, useCallback } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Modal } from "../../ui/modal/Modal";
import {
  updateUser,
  updatePassword,
  getHeaders,
  deleteProfileImage,
} from "../../../actions/user";
import { toast } from "react-toastify";
import { UserContext } from "../../context";
import Avatar from "@mui/material/Avatar";
import { useDropzone } from "react-dropzone";
import axios from "axios";

interface Props {
  modal: boolean;
  setModal: (modal: boolean) => void;
  onModalClose: any;
}

export const UpdateProfile: React.FC<Props> = ({
  modal,
  setModal,
  onModalClose,
}) => {
  const { user, setUser } = useContext(UserContext);

  const [username, setUsername] = useState<string>(user.username);
  const [fullName, setFullName] = useState<string>(user.fullName || "");
  const [email, seEmail] = useState<string>(user.email);
  const [mfa, seMfa] = useState<string>(user.mfa);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [expanded, setExpanded] = useState<string | false>(false);
  const [openVendorId, setOpenVendorId] = useState<any>();

  const { getRootProps, getInputProps } = useDropzone({
    accept: ["image/png", "image/jpeg"],
    onDropRejected: () => {
      toast.error("File type rejected. Only png or jpeg files are allowed.");
    },
    maxFiles: 1,
    // 10mb
    maxSize: 1e7,
    onDropAccepted: async (acceptedFiles) => {
      try {
        const formData = new FormData();
        formData.append("file", acceptedFiles[0]);
        const res = await axios.post("/user/image", formData, getHeaders());
        setUser({ ...user, profileImage: res.data });
      } catch (error) {
        console.error(error);
        toast.error("Something went wrong");
      }
    },
  });

  const handleRemovePhoto = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();

      try {
        await deleteProfileImage();
        setUser((user: any) => ({ ...user, profileImage: "" }));
      } catch (error) {
        console.error(error);
      }
    },
    [setUser]
  );

  const onUpdateHandler = async (e: React.MouseEvent) => {
    e.preventDefault();

    await updateUser(user.username, { username, email, mfa, fullName });
    setUser((user: any) => ({ ...user, username, email, mfa, fullName }));
    toast.success("Profile has been updated successfully");

    if (
      password !== "" &&
      currentPassword !== "" &&
      password === confirmPassword
    ) {
      try {
        await updatePassword(password, currentPassword);
        toast.success("Password has been changed successfully");
        setExpanded(false);
      } catch (error: any) {
        console.error(error);
        toast.error(error.response?.data?.message || "Something went wrong");
      }
    }
  };

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleAccordClick = (id: any) => {
    if (openVendorId === id) setOpenVendorId("");
    if (openVendorId !== id) setOpenVendorId(id);
  };

  return (
    <Modal onModalClose={onModalClose} isOpened={modal}>
      <>
        <div className="profile__container">
          <h3 className="create-heading">My Profile Settings</h3>
          <div className="table-border-bottom pb2"></div>
          <span className="profile-popup-subtitle">
            Here you manage your personal information.
          </span>
          <div className="width100">
            <div className="in-row width100">
              <div className="in-column width100 justify-start align-start">
                <div className=" in-column mt2 align-start width90">
                  <div>Real Name:</div>
                  <input
                    className="formEdit__input"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </div>
                <div className=" in-column mt2 align-start width90">
                  <div>Username:</div>
                  <input
                    className="formEdit__input"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className=" in-column mt2 align-start width90">
                  <div>E-mail:</div>
                  <input
                    className="formEdit__input"
                    value={email}
                    onChange={(e) => seEmail(e.target.value)}
                  />
                </div>
                <div className=" in-column mt2 align-start width90">
                  <div>MFA:</div>
                  <select
                    className="formEdit__input"
                    value={mfa}
                    onChange={(e) => seMfa(e.target.value)}
                  >
                    <option>NONE</option>
                    <option>EMAIL</option>
                  </select>
                </div>
              </div>
              <div className="in-column width90 justify-start mt6">
                <Avatar
                  alt={fullName}
                  className="profile-bg-image"
                  src={
                    user.profileImage === undefined ? "df" : user.profileImage
                  }
                  sx={{ width: 120, height: 120, borderRadius: "60px" }}
                />
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <span className="profile-active-text pointer">
                    Edit photo
                  </span>
                </div>
                {user.profileImage && (
                  <span
                    className="profile-active-text pointer"
                    onClick={handleRemovePhoto}
                  >
                    Remove photo
                  </span>
                )}
              </div>
            </div>
            <div className=" in-column align-start width100">
              <div className="width100">
                <Accordion
                  className="accordion-nav width100"
                  expanded={expanded === "password"}
                  onChange={handleChange("password")}
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    className="p0 width100"
                    id="panel1a-header"
                    onClick={() => {
                      handleAccordClick(expanded);
                    }}
                  >
                    {expanded === "password" ? (
                      <span className="profile-active-text">
                        Keep Actual Password
                      </span>
                    ) : (
                      <span className="profile-active-text">
                        Change Password
                      </span>
                    )}
                  </AccordionSummary>
                  <AccordionDetails className="pn width100">
                    <div className="update-password">
                      <div className="in-column width100  align-start width50">
                        <div>Actual password:</div>
                        <input
                          type="password"
                          className="formEdit__input"
                          value={currentPassword}
                          onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                      </div>
                      <div className="in-row width100 align-start">
                        <div className="in-column width100  align-start ">
                          <div>New password:</div>
                          <input
                            type="password"
                            className="formEdit__input"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        <div className="in-column width100  align-start ml3">
                          <div>Confirm new password:</div>
                          <input
                            type="password"
                            className="formEdit__input"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
          <div className="table-modal-border"></div>
          <div className="table-modal-form-button-box width100">
            <button
              className="table-modal-form-button-cancel"
              onClick={() => setModal(false)}
            >
              Cancel
            </button>
            <button
              className="table-modal-form-button"
              onClick={onUpdateHandler}
            >
              Update
            </button>
          </div>
        </div>
      </>
    </Modal>
  );
};
