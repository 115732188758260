import axios from "axios";
import { getHeaders } from "./user";

export const getVendors = async () => {
	const res = await axios.get("/vendorsCombined", getHeaders());
	return res.data.vendors;
};

export const getVendorsActive = async () => {
	const res = await axios.get(`/vendor?isActive=1`, getHeaders());

	if (res.status !== 200) {
		return new Error("Something goes wrong");
	}

	return res.data.vendors;
};

export async function createVendor(body: any) {
	const formData = new FormData();
	formData.append("file", body.file);
	formData.append("name", body.name);
	formData.append("phone", body.phone);
	formData.append("country", body.country);
	formData.append("zeoosClient", body.zeoosClient);
	formData.append("contacts", body.contacts);

	const res = await axios.post("/vendor", formData, getHeaders());
	return res.data.createdVendor;
}

export async function updateVendor(body: any) {
	const formData = new FormData();
	formData.append("file", body.file);
	formData.append("name", body.name);
	formData.append("city", body.city);
	formData.append("rate", body.rate);
	formData.append("country", body.country);
	formData.append("zeoosClient", body.zeoosClient);
	formData.append("email", body.email);
	formData.append("phone", body.phone);
	formData.append("isActive", body.isActive);
	formData.append("contacts", JSON.stringify(body.contacts));

	const res = await axios.put(`/vendor/${body.id}`, formData, getHeaders());
	return res.data.updatedVendor;
}

export async function deleteVendor(vendor: any) {
	await axios.put(`/vendor/${vendor.id}`, getHeaders());
}
