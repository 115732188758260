import React, { useState, useEffect } from "react";
import { Modal } from "../../ui/modal/Modal";
import { toStringConverter } from "../tables/gestao/UpdateGestao";
import { fillLevel } from "./Performance";
import { getShipment } from "../../../actions/billings";
import Preloader from "../../ui/preloader/Preloader";

interface Props {
    shipmentId: number;
    setOpenShipment: (openShipment: boolean) => void;
    openShipment: boolean;
}

export const Shipment: React.FC<Props> = ({ shipmentId, setOpenShipment, openShipment }) => {
    const [shipment, setShipment] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        async function initializeState() {
            try {
                setShipment(await getShipment(shipmentId));
            } catch (error) {
                console.error(error);
            }

            setLoading(false);
        }
        initializeState();
        // eslint-disable-next-line
    }, []);

    const onModalClose = (e: React.MouseEvent) => {
        e.stopPropagation();
        setOpenShipment(false);
    };

    return loading ? (
		<Preloader />
	) : (
        <>
            <Modal onModalClose={onModalClose} isOpened={openShipment}>
                <div className="billing-modal-cont">
                    <div>
                        <div className="table-modal-title-box m0">
                            <div className="mapping-modal-title-text">Shipment {shipmentId}</div>
                            <div className="in-row align-center">
                                <div className="billing-modal-text pr2">Status:</div>
                                <div className="billing-modal-text billing-redtext">{shipment.shipment_payment_status}</div>
                            </div>
                        </div>
                        <form className="table-modal-form-cont">
                            <div className="table-modal-border"></div>
                            <div>
                                <div className="billing-modal-table-line height30">
                                    <div className="billing-modal-table-line-active" style={{ width: fillLevel(shipment.valor_recebido, shipment.total_da_invoice) }}></div>
                                </div>
                                <div className="billing-modal-table-line-box">
                                    <div className="billing-text bold green">Total recebido: {toStringConverter(shipment.valor_recebido)}€</div>
                                    <div className="billing-text bold grey">Total da Invoice: {toStringConverter(shipment.total_da_invoice)}€</div>
                                </div>
                            </div>
                            <table className="mapping-modal-table">
                                <thead>
                                    <tr className="mapping-modal-results-title">
                                        <th className="billing-modal-medium">SKU</th>
                                        <th className="billing-modal-large">Name</th>
                                        <th className="billing-modal-xmedium justify-center">Vendidos / Total</th>
                                        <th className="billing-modal-xmedium justify-center">Total Recebido:</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {shipment.purchase_order_lines_ids.map((order: any, index: number) => (
                                            <React.Fragment key={index}>
                                                <tr className="billing-modal-result-box" >
                                                    <td className="billing-modal-medium">
                                                        <div className="billin-text">{order.product_sku}</div>
                                                    </td>
                                                    <td className="billing-modal-large justify-start">
                                                        <div className="billing-text text-start">{order.product_name}</div>
                                                    </td>
                                                    <td className="billing-modal-xmedium justify-center">
                                                        <div className="billing-text">{order.amount_sold_per_product} / {order.product_qty}</div>
                                                    </td>
                                                    <td className="billing-modal-xmedium justify-center">
                                                        <div className="billing-text">{toStringConverter(order.valor_recebido_per_product)}€</div>
                                                    </td>
                                                </tr>
                                                <tr className="billing-modal-table-line height2">
                                                    <div className="billing-modal-table-line-active" style={{ width: fillLevel(order.amount_sold_per_product, order.product_qty) }}></div>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                </tbody>
                            </table>
                            <div className="table-modal-border"></div>
                            <div className="table-modal-form-button-box width100 justify-between">
                                {/* <div className="billing-modal-text underline initial pointer">Ir para Invoice</div> */}
                                <div></div>
                                <button className="table-modal-form-button" onClick={() => setOpenShipment(false)}>
                                    fechar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    );
}
