import React from 'react';
import './preloader.css';

const Preloader: React.FC = () => {
	return (
		<div className='preloader'>
			<div className="preloader-margin">
				<div className='ldsRoller'>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		</div>
	);
};

export default Preloader;
