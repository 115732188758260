import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

interface IPropsText {
  text: any;
  children?: any;
}

export const TooltipInfoText: React.FC<IPropsText> = ({ text, children }) => {

  return (
    <Tooltip title={text} className='pointer'>
      <div>
        <img
          className="info-button"
          src="/icons/information-button.svg"
          width="15px;"
          alt=""
        />
        {children}
      </div>
    </Tooltip>
  )
}