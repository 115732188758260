import React from "react";
import "./gestao.css";
import "../tables.css";
import * as _ from "lodash";

interface Props {
	setCountry: (country: string) => void;
	country: string;
	marketplaces: any;
}

export const SelectCountry: React.FC<Props> = ({
	setCountry,
	country,
	marketplaces,
}) => {
	const countries = _.uniq(marketplaces.map((item: any) => item.country));

	function countriesArray() {
		return ["All Countries", ...countries];
	}

	return (
		<div className="table-title-cont">
			<div className="table-title-sort">
				<div className="gestao-select-cont">
					<div className="gestao-select-text">Selecione o país:</div>
					{countriesArray().map((item: any, index: number) => (
						<button
							className={`gestao-select-button ${country === item ? "gestao-active" : ""
								}`}
							key={index}
							onClick={(e) => {
								e.preventDefault();
								setCountry(item);
							}}
						>
							{item}
						</button>
					))}
				</div>
			</div>
		</div>
	);
};
