import axios from "axios";
import { IPaginatedRequest, IPagination } from "../interfaces";
import { getHeaders } from "./user";
import queryString from "query-string";

export const getMarketplace = async (paginationParams: IPagination) => {
	const res = await axios.get(
		`/getAllMarketplaces?limit=${paginationParams.limit}&page=${paginationParams.currentPage}`,
		getHeaders()
	);

	const { data, success } = res.data;

	if (!success) {
		return new Error("Something goes wrong");
	}

	return data;
};

export const getMarketplacesAdmin = async () => {
	const res = await axios.get(`/getAllMarketplaces/admin`, getHeaders());

	return res.data;
};

export const getSanitizedMarketplaces = async () => {
	const res = await axios.get("/getMarketplaces", getHeaders());

	const { data, success } = res.data;

	if (!success) {
		return new Error("Something goes wrong");
	}

	return data;
};

export const findMarketplace = async (zeoosName: string) => {
	const res = await axios.get(`/marketplace/${zeoosName}`, getHeaders());

	const { data, success } = res.data;

	if (!success) {
		return new Error("Something goes wrong");
	}

	return data;
};

export const createMarketplace = async (body: any) => {
	const res = await axios.post("/createMarketplace", body, getHeaders());

	const { data, success } = res.data;

	if (!success) {
		return new Error("Something goes wrong");
	}

	return data;
};

export const editMarketplace = async (body: any) => {
	const res = await axios.put(
		`/marketplace/${body.zeoosName}`,
		body,
		getHeaders()
	);

	const { data, success } = res.data;

	if (!success) {
		return new Error("Something goes wrong");
	}

	return data;
};

export const deleteMarketplace = async (name: string) => {
	const res = await axios.delete(`/marketplace/${name}`, getHeaders());

	const { data, success } = res.data;

	if (!success) {
		return new Error("Something goes wrong");
	}

	return data;
};

export const generateDottFile = async () => {
	const res = await axios.get("/getDottFeed", getHeaders());

	const { data, success } = res.data;

	if (!success) {
		return new Error("Something goes wrong");
	}

	return data;
};

export const generateFnacFile = async () => {
	const res = await axios.get("/getFnacFeed", getHeaders());

	const { data, success } = res.data;

	if (!success) {
		return new Error("Something goes wrong");
	}

	return data;
};

export const generateAmazonFile = async () => {
	const res = await axios.get("/getAmazonFeed", getHeaders());

	const { data, success } = res.data;

	if (!success) {
		return new Error("Something goes wrong");
	}

	return data;
};

export const sendDataToFnac = async () => {
	const res = await axios.post("/send-data-to-fnac");
	return res.data;
};

export const getCombinedProductsLong = async () => {
	const res = await axios.get("/combinedProducts/long", getHeaders());
	return res.data;
};

export const getCombinedProducts = async () => {
	const res = await axios.get("/combinedProducts", getHeaders());
	return res.data.data.products;
};

export const generateProducts = async (marketplace?: string) => {
	const res = await axios.post(
		"/combinedProducts",
		{ marketplace },
		getHeaders()
	);
	return res.data;
};

export const updateProduct = async (toUpdate: any, zeoosName: string) => {
	await axios.put(
		`/combinedProducts/${zeoosName}`,
		{
			toUpdate,
		},
		getHeaders()
	);
};

export async function getSupportedMarketplaces() {
	return (await axios.get("supportedMarketplaces", getHeaders())).data
		.supportedMarketplaces;
}

export async function getMarketplaceSetupConfig() {
	return (await axios.get("marketplaceSetupConfig", getHeaders())).data
		.marketplaceSetupConfig;
}

export async function postProductDetails(data: any) {
	const res = await axios.post("/productDetails", data, getHeaders());
	return res.data;
}
export async function getProductDetails(data: any) {
	const res = await axios.get(
		`/productDetails/${data.zeoosName}/${data.sku}`,
		getHeaders()
	);

	return res.data;
}

export async function getPricingBreakdownData(data: any) {
	const res = await axios.get(
		`/productPriceBreakdownData/${data.zeoosName}/${data.sku}`,
		getHeaders()
	);

	return res.data;
}

export async function calculatePriceBreakdown(data: any) {
	const res = await axios.post(`/calculatePriceBreakdown`, data, getHeaders());

	return res.data;
}

export async function calculateReversePriceBreakdown(data: any) {
	const res = await axios.post(
		`/calculateReversePriceBreakdown`,
		data,
		getHeaders()
	);

	return res.data;
}

export async function updateProductDetails(data: any) {
	const res = await axios.put(
		`/productDetails/${data.zeoosName}/${data.sku}`,
		data,
		getHeaders()
	);

	return res.data;
}

export async function uploadCsvSeed(file: File, zeoosName: string) {
	const formData = new FormData();
	formData.append("file", file);
	const res = await axios.post(
		`/marketplace/${zeoosName}/seedManually`,
		formData,
		getHeaders()
	);
	return res.data;
}

export const getProductsFromPlatform = async (zeoosName: string) => {
	const res = await axios.get(
		`/getProductsFromMarketplace/${zeoosName}`,
		getHeaders()
	);

	return res.data.products;
};

export const updateProductOnAPlatform = async (body: any) => {
	const res = await axios.post(
		"/updateProductOnMarketplace",
		body,
		getHeaders()
	);

	const data = res.data.status;

	return data.toString();
};

export const setSchedule = async (body: any) => {
	const res = await axios.post("/marketplace/setCronJob", body, getHeaders());
	return res.data;
};

export const removeSchedule = async (body: any) => {
	const res = await axios.post(
		"/marketplace/removeCronJob",
		body,
		getHeaders()
	);

	return res.data;
};

export const uploadImage = async (body: any) => {
	const formData = new FormData();
	formData.append("file", body.imageFile);
	formData.append("zeoosName", body.zeoosName);
	formData.append("id", body.id);

	const res = await axios.post(
		"/marketplace/upload-file",
		formData,
		getHeaders({ "Content-Type": "multipart/form-data" })
	);

	return res.data;
};

export const getJSONScheme = async () => {
	const res = await axios.get("/scheme", getHeaders());

	return res.data.wineScheme.tabs;
};

export const saveProductInfo = async (body: any) => {
	const res = await axios.post(`/productInfo`, body, getHeaders());
	return res.data.productInfo;
};

export const getProductInfo = async (sku: string, lang: string) => {
	const res = await axios.get(`/productInfo/${sku}?lang=${lang}`, getHeaders());

	return res.data;
};

export const getCombinedProductBySku = async (sku: string) => {
	const res = await axios.get(`/combinedProducts/${sku}`, getHeaders());

	return res.data.data.products?.[0];
};

export const getReviews = async (sku: string) => {
	const res = await axios.get(`/reviews/${sku}`, getHeaders());

	return res.data.data;
};

export async function exportMarketplaceData() {
	await axios.get("/marketplaceData/export", getHeaders());
}

export const createPaginatedRequest =
	(url: string) => async (pagination: IPaginatedRequest) => {
	  const res = await axios.get(
	    `${url}?${queryString.stringify(pagination)}`,
	    getHeaders()
	  );

		return res.data as any;
	};

export const getPaginatedMarketplaceProducts = createPaginatedRequest(
	"/pim/products"
);
export const getPaginatedInventoryProducts = createPaginatedRequest(
	"/inventory/products"
);
