import { createContext } from "react";

export const UserContext: any = createContext(true);
export const CountriesContext: any = createContext(true);

let token: string | null = null;

export function getToken() {
	return token;
}

export function setToken(newToken: string) {
	token = newToken;
}
