import React, { useEffect, useState } from "react";
import { getAllShipments } from "../../../actions/billings";
import Preloader from "../../ui/preloader/Preloader";
import "./billing.css";
import "./BillingTable";
import { BillingTable } from "./BillingTable";
import { SingleBilling } from "./single-billing/SingleBilling";

export default function Billing() {
    const [loading, setLoading] = useState<boolean>(true);
    const [shipments, setShipments] = useState<any>([]);
    const [billingsWindow, setBillingsWindow] = useState<boolean>(false);
    const [billingsId, setBillingsId] = useState<any>([]);

    useEffect(() => {
        async function initializeState() {
            try {
                setShipments(await getAllShipments());
            } catch (error) {
                console.error(error);
            }

            setLoading(false);
        }
        initializeState();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="main-body">
                <div className="main-container">
                    {!loading ? (
                        <>
                            {/* <div className="table-title-cont">
                                <div className="table-title-sort">
                                    <div className="compa-select-cont">
                                        <div className="compa-select-text">Filtrar por:</div>
                                        <select
                                            id="compa-channels"
                                            name="compa-channels"
                                            required
                                            className="compa-select"
                                        >
                                            <option className="compa-option" value="">
                                                All Channels
                                    </option>
                                            <option className="compa-option" value="First">
                                                Amazon ES
                                    </option>
                                            <option className="compa-option" value="Second">
                                                Vinuus PT
                                    </option>
                                        </select>
                                    </div>
                                </div>
                            </div> */}
                            {billingsWindow ? (
                                <SingleBilling setBillingsWindow={setBillingsWindow} billingsId={billingsId} />
                            ) : (
                                <BillingTable shipments={shipments} setBillingsWindow={setBillingsWindow} setBillingsId={setBillingsId} />
                            )}
                        </>
                    ) : (
                        <Preloader />
                    )}
                </div>
            </div>
        </>
    );
}
