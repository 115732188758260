import axios from "axios";
import { getHeaders } from "./user";

export async function getShipments() {
	const res = await axios.get("/shipments", getHeaders());
	return res.data.shipments;
}

export async function getAllShipments() {
	const res = await axios.get("/billings/allShipments", getHeaders());
	return res.data.shipments;
}

export async function getShipmentsPerVendor() {
	const res = await axios.get("/billings/shipmentsPerVendor", getHeaders());
	return res.data.shipments;
}

export async function getShipment(id: number) {
	const res = await axios.get(`/billings/shipment/${id}`, getHeaders());
	return res.data.shipment[0];
}

export async function getBilling(id: number) {
	const res = await axios.get(`/billings/billing/${id}`, getHeaders());
	return res.data.billing;
}

export async function getInvoice(ref: string) {
	const res = await axios.post("/billings/invoice", { ref }, getHeaders());
	return res.data.invoice[0];
}

export async function getBillingPrice(id: number) {
	const res = await axios.get(`/billings/billingPrice/${id}`, getHeaders());
	return res.data.billing[0].bill_line_ids[0];
}
