import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toastMessage.css';

const ToastMessage: React.FC = ({ ...props }) => {
    return (
        <div className='toast-message-container'>
            <ToastContainer
                position="bottom-right"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                {...props}
            />
        </div>
    );
}

export default ToastMessage;
