import React from "react";
import "./screen.css";

export const Screen = () => {
	return (
		<div className="wrapper">
			<div className="containerInterval">
				<h1>Processing...</h1>
			</div>
		</div>
	);
};
