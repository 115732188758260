import React, { useContext, useState } from "react";
import { Redirect, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { loginUser } from "../../../actions/user";
import { UserContext } from "../../context";
import "./login.css";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { toast } from "react-toastify";
import Contato from './Contato';
import Language from './Language';

const Login: React.FC<any> = ({ history }) => {
  const [state, setState] = useState({
    checkedB: true,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { user, setUser } = useContext(UserContext);
  const [textButton, setTextButton] = useState("Login");
  const [classButton, setClassButton] = useState("");

  if (user) {
    return history.location.state ? (
      <Redirect to={`${history.location.state.from.pathname}`} />
    ) : (
      <Redirect to="/" />
    );
  }

  const handleLogin = async (e: any) => {
    setTextButton('Aguarde...');
    setClassButton('wait');
    e.preventDefault();

    try {
      const { user } = await loginUser({ email, password }, state.checkedB);
      setUser(user);
    } catch (error: any) {
      console.error('error', error);
      toast.error(error.response.data.message, { autoClose: 5000 });
      setTextButton('Login');
      setClassButton('');
    }
  };

  return (
    <div>
      <div className="s_main-login">
        <div className="titel_box">
          <img src="/icons/logo.svg" alt="" />
        </div>
        <div className="login-box">
          <div className="in-column">
            <form className="login-form-block" onSubmit={handleLogin}>
              <div className="email_text_box">
                <label className="email-text-login">Email:</label>
              </div>
              <div className="login-input">
                <img
                  className="image-profile"
                  src="/icons/carbon_email.svg"
                  alt="settings"
                  width="30px"
                  height="30px"
                />
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  className="email-field"
                  name="Email"
                  data-name="Email"
                  id="Email"
                  required
                />
              </div>
              <div className="email_text_box">
                <label className="password-text-login">Senha:</label>
              </div>
              <div className="login-input">
                <div>
                  <img
                    className="image-profile"
                    src="/icons/lock-password.svg"
                    alt="settings"
                    width="30px"
                    height="30px"
                  />
                </div>
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  className="password-field"
                  name="Password"
                  data-name="Password"
                  id="Password"
                  required
                ></input>
              </div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.checkedB}
                      onChange={handleChange}
                      name="checkedB"
                      color="primary"
                      required
                    />
                  }
                  className="lembre-me"
                  label="Lembre-me"
                />
              </FormGroup>
              <div className="button_box">
                <button onClick={handleLogin}
                  disabled={(classButton === 'wait' ? true : false)}
                  className={`log-in-login ${classButton}`}>
                  {textButton}
                </button>
              </div>
              <div className="login-text-box">
                <div className="esqueci-text">
                  <Link to="/login/esqueci">
                    Esqueci a senha
                  </Link>
                </div>
                <Language />
              </div>
            </form>
          </div>
        </div>
        <Contato />
      </div>
    </div>
  );
};

export default withRouter(Login);
