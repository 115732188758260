import React, { useContext, useEffect, useState } from "react";
import "./acompanhamento.css";
import "../tables.css";
// import moment from "moment";
import { PaginationComponent } from "../../../ui/pagination/PaginationComponent";
import { CountriesContext } from "../../../context";
import { ICountryM } from "../../../ui/country-flag/CountryFlag";

interface Props {
	orders: any;
}

export function renderUnifiedOrderStatus(order: any) {
	const pickStatus = (statuses: string[]) => {
		for (const status of statuses) {
			if (status !== '') {
				return status;
			}
		}
		return null;
	}
	const status: any = pickStatus([order.sale_lengow_status, order.sale_shopify_status]);
	if (['fulfilled', 'shipped', undefined].includes(status)) {
		return 'fulfilled';
	}
	if (['unfulfilled', 'closed', false].includes(status)) {
		return 'unfulfilled';
	}
	return status;
}

export const TableAcompanhamento: React.FC<Props> = ({ orders }) => {
	const countries = useContext(CountriesContext) as ICountryM[];

	const rowsPerPage = 10;

	const [search, setSearch] = useState<any>("");
	const [paginatedData, setPaginatedData] = useState<any>(orders.slice(0, rowsPerPage));
	const [results, setResults] = useState<any>(orders);

	useEffect(() => {
		const results = orders
			// eslint-disable-next-line
			.filter((item: any) => {
				if (search === "") {
					return item;
				} else if (
					Object.keys(item).filter(
						(x: any) =>
							typeof item[x] === "string" &&
							item[x].toLowerCase().includes(search.toLowerCase())
					)?.length > 0 ||
					String(item.sale_amount).includes(search)
				) {
					return item;
				}
			});
		setResults(results);
		setPaginatedData(results.slice(0, rowsPerPage));
	}, [search, orders]);

	return (
		<div className="table-main-cont">
			<div className="table-value-search-cont">
				<div className="table-value">{results.length} pedidos</div>
				<div className="table-search-box">
					<form className="table-search-form" onSubmit={(e) => e.preventDefault()}>
						<input
							className="table-search-input"
							placeholder="Search order"
							onChange={(e) => setSearch(e.target.value)}
						/>
						<img
							className="table-search-image"
							src="/icons/search.svg"
							width="25px;"
							alt=""
						/>
					</form>
				</div>
			</div>
			<div className="table-border-bottom"></div>
			<div className="table-overflow">
				<table className="table-results">
					<thead>
						<tr className="table-results-title">
							<th className="acompan-status">Status</th>
							<th className="table-border-right"></th>
							<th className="acompan-order">Order ID</th>
							<th className="table-border-right"></th>
							<th className="acompan-channel">Channel</th>
							<th className="table-border-right"></th>
							<th className="acompan-date">Date</th>
							<th className="table-border-right"></th>
							<th className="acompan-name">Customer Name</th>
							<th className="table-border-right"></th>
							<th className="acompan-country">Country</th>
							<th className="table-border-right"></th>
							<th className="acompan-amount">Amount</th>
							{/* <th className="table-border-right"></th> */}
							{/* <th className="acompan-mail"></th> */}
						</tr>
					</thead>
					<tbody>
						{paginatedData.map((item: any, index: number) => (
							<tr className="table-result-box" key={index}>
								<td className="acompan-status">
									<div className="acompan-status-closed">
										{renderUnifiedOrderStatus(item)}
									</div>
								</td>
								<td className="table-border-right"></td>
								<td className="acompan-order">
									<div className="acompan-order-text" style={{ textDecoration: 'none' }}>{item.order_id}</div>
								</td>
								<td className="table-border-right"></td>
								<td className="acompan-channel">
									<div className="acompan-channel-text">
										{item.sale_marketplace_account}
									</div>
								</td>
								<td className="table-border-right"></td>
								<td className="acompan-date">
									<div className="acompan-date-text">
										{item.sale_date.slice(0, 10).replaceAll("-", "/")}
										{/* {moment(item.sale_date).format("MMM Do YY")} */}
									</div>
								</td>
								<td className="table-border-right"></td>
								<td className="acompan-name">
									<div className="acompan-name-text">{item.customer}</div>
								</td>
								<td className="table-border-right"></td>
								<td className="acompan-country">
									{countries.find((x: any) => item.country === x.name) ? (
										<img
											className="table-search-image"
											src={
												countries.find((x: any) => item.country === x.name)?.iconUrl
											}
											width="32px;"
											alt=""
										/>
									) : (
										<span>{item.country}</span>
									)}
								</td>
								<td className="table-border-right"></td>
								<td className="acompan-amount">
									<div className="acompan-amount-text">
										{item.sale_amount.toLocaleString("de-DE", {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										})}{" "}
										€
									</div>
								</td>
								{/* <td className="table-border-right"></td> */}
								{/* <td className="acompan-mail">
									<img
										className="table-search-image pointer"
										src="/icons/acompan-mail.svg"
										width="32px;"
										alt=""
									/>
								</td> */}
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<div>
				<PaginationComponent data={results} rowsPerPage={rowsPerPage} setPaginatedData={setPaginatedData} />
			</div>
		</div>
	);
};
