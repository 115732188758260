import React from 'react';
import "./noties.css";
import { readAllNotifcations } from '../../../../actions/notifications';
import { INote } from '../../../../interfaces';
import moment from 'moment';
import { StyledMenuComponent } from '../NotiesSection';

interface Props {
  noties: INote[];
  anchorEl: null | HTMLElement;
  setAnchorEl: (anchorEl: null | HTMLElement) => void;
  allReadCallback: () => void;
}

export const Noties: React.FC<Props> = ({ noties, anchorEl, setAnchorEl, allReadCallback }) => {
  const readAllNoties = async () => {
    await readAllNotifcations();
    allReadCallback();
  }

  return (
    <div>
      <StyledMenuComponent anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        <div className="notification-title">
          <img src="/icons/notification.svg" alt="" />
          <div className="pl1">NOTIFICAÇÕES</div>
        </div>
        {[...noties].reverse().map((note: INote, index: number) => (
          <div key={index}>
            <div className="notification-border"></div>
            <div className={`menu-item ${note.wasRead && "menu-item-active"}`}>
              <div className="menu-item-container">
                <div className="in-row">
                  {!note.wasRead && <img src="/icons/new-notification.svg" alt="" width="6px;" className='mr1 ml1' />}
                  <div className="notification-image-box">
                    <img src="/icons/notification-graph.svg" alt="" width="28px;" />
                  </div>
                </div>
                <div>
                  <div className="note-title">{note.title}</div>
                  <div className="note-date">{moment(new Date(note.created)).fromNow()}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="menu-item-button">
          <div className="menu-item-button-container">
            <button className="read-all-button" onClick={readAllNoties}>Read all</button>
          </div>
        </div>
      </StyledMenuComponent>
    </div >
  );
}
