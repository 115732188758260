import React, { useState } from "react";
import "./comparador.css";
import "../tables.css";
import { Modal } from "../../../ui/modal/Modal";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
  }),
);

export default function Comparador() {
  const classes = useStyles();
  const [modal, setModal] = useState<boolean>(false);

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal(false);
  };

  return (
    <>
      <div className="main-body">
        <div className="main-container">
          <div className="table-title-cont">
            <div className="table-title-sort">
              <div className="compa-select-cont">
                <div className="compa-select-text">Selecione o canal:</div>
                <select
                  id="compa-channels"
                  name="compa-channels"
                  required
                  className="compa-select"
                >
                  <option className="compa-option" value="">
                    All Channels
                  </option>
                  <option className="compa-option" value="First">
                    Amazon ES
                  </option>
                  <option className="compa-option" value="Second">
                    Vinuus PT
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="table-main-cont">
            <div className="table-value-search-cont">
              <div className="table-value">52 produtos em vinuus PT</div>
              <div className="table-search-box">
                <form className="table-search-form">
                  <input
                    className="table-search-input"
                    placeholder="Search product"
                  />
                  <img
                    className="table-search-image"
                    src="/icons/search.svg"
                    width="25px;"
                    alt=""
                  />
                </form>
              </div>
            </div>
            <div className="table-border-bottom"></div>
            <div className="table-overflow">
              <table className="table-results">
                <thead>
                  <tr className="table-results-title">
                    <th className="compa-sku">SKU</th>
                    <th className="table-border-right"></th>
                    <th className="compa-ean">EAN</th>
                    <th className="table-border-right"></th>
                    <th className="compa-title">Title</th>
                    <th className="table-border-right"></th>
                    <th className="compa-vinuus">Vinuus PT</th>
                    <th className="table-border-right"></th>
                    <th className="compa-bodeboca">Bodeboca</th>
                    <th className="table-border-right"></th>
                    <th className="compa-amazon">Amazon ESP</th>
                    <th className="table-border-right"></th>
                    <th className="compa-dott">DOTT PT</th>
                    <th className="table-border-right"></th>
                    <th className="compa-fnac">FNAC PT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="table-result-box">
                    <td className="compa-sku">
                      <div className="compa-sku-text">0001</div>
                    </td>
                    <td className="table-border-right"></td>
                    <td className="compa-ean">
                      <div className="compa-ean-text">0123456789</div>
                    </td>
                    <td className="table-border-right"></td>
                    <td className="compa-title">
                      <div className="compa-title-text">
                        Pack 3 Garrafas Porto Discovering 0,75L - 1 Porto
                        Sequeira Tawny, 1 Porto LBV 2015, 1 Porto Sequeira Tawny
                        10 anos
                      </div>
                    </td>
                    <td className="table-border-right"></td>
                    <td
                      onClick={() => {
                        setModal(true);
                      }}
                      className="compa-vinuus"
                    >
                      <div className="compa-box-update">
                        <div className="compa-cost-text">43,90€</div>
                        <div className="compa-cost-update">update</div>
                      </div>
                    </td>
                    <td className="table-border-right"></td>
                    <td className="compa-bodeboca">
                      <div className="compa-box">
                        <div className="compa-cost-text">51,90€</div>
                        <img
                          className="compa-arrow"
                          src="/icons/arrow-up.svg"
                          width="24px;"
                          alt=""
                        />
                        {/* <img
                          className="compa-arrow"
                          src="/icons/arrow-down.svg"
                          width="24px;"
                          alt=""
                        /> */}
                      </div>
                    </td>
                    <td className="table-border-right"></td>
                    <td className="compa-amazon">
                      <div className="compa-box">
                        <div className="compa-cost-text">51,90€</div>
                        <img
                          className="compa-arrow"
                          src="/icons/arrow-up.svg"
                          width="24px;"
                          alt=""
                        />
                        {/* <img
                          className="compa-arrow"
                          src="/icons/arrow-down.svg"
                          width="24px;"
                          alt=""
                        /> */}
                      </div>
                    </td>
                    <td className="table-border-right"></td>
                    <td className="compa-dott">
                      <div className="compa-box">
                        <div className="compa-cost-text">51,90€</div>
                        <img
                          className="compa-arrow"
                          src="/icons/arrow-up.svg"
                          width="24px;"
                          alt=""
                        />
                        {/* <img
                          className="compa-arrow"
                          src="/icons/arrow-down.svg"
                          width="24px;"
                          alt=""
                        /> */}
                      </div>
                    </td>
                    <td className="table-border-right"></td>
                    <td className="compa-fnac">
                      <div className="compa-box">
                        <div className="compa-cost-text">51,90€</div>
                        <img
                          className="compa-arrow"
                          src="/icons/arrow-up.svg"
                          width="24px;"
                          alt=""
                        />
                        {/* <img
                          className="compa-arrow"
                          src="/icons/arrow-down.svg"
                          width="24px;"
                          alt=""
                        /> */}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={classes.root}>
              <div className="table-pagination">
                <div className="table-pagination-text">Mostrando 1 - 50</div>
                <Pagination count={10} variant="outlined" shape="rounded" color="secondary" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal && (
        <Modal onModalClose={onModalClose} isOpened={modal}>
          <div className="table-modal-cont">
            <div className="table-modal-title-box">
              <div className="table-modal-title-pricing">Pricing Breakdown</div>
              <div className="table-modal-title-title">0001 - AMAZON ES</div>
            </div>
            <form className="table-modal-form-cont">
              <div className="table-modal-border"></div>
              <div className="table-modal-form-box">
                <label className="table-modal-form-title bold">Stock:</label>
                <input
                  className="table-modal-form-value table-modal-border-value"
                  placeholder="98"
                />
              </div>
              <div className="table-modal-border"></div>
              <div className="table-modal-form-box">
                <label className="table-modal-form-title bold">PVP:</label>
                <input
                  className="table-modal-form-value table-modal-border-value"
                  placeholder="44.90€"
                />
              </div>
              <div className="table-modal-form-box">
                <label className="table-modal-form-title">IVA:</label>
                <div className="table-modal-form-value">9.42€</div>
                <div className="table-modal-form-difference">(21%)</div>
              </div>
              <div className="table-modal-form-box">
                <label className="table-modal-form-title">IEC:</label>
                <div className="table-modal-form-value">0.56€</div>
              </div>
              <div className="table-modal-form-box">
                <label className="table-modal-form-title">Frete:</label>
                <div className="table-modal-form-value">3.12€</div>
              </div>
              <div className="table-modal-form-box">
                <label className="table-modal-form-title">
                  Fator $ por País/Canal:
                </label>
                <input
                  className="table-modal-form-value table-modal-border-value"
                  placeholder="1.23€"
                />
              </div>
              <div className="table-modal-form-box">
                <label className="table-modal-form-title">Preço Base:</label>
                <input
                  className="table-modal-form-value table-modal-border-value"
                  placeholder="30.57€"
                />
              </div>
              <div className="table-modal-form-box">
                <label className="table-modal-form-title">Vinuus Rate:</label>
                <div className="table-modal-form-value">15.28€</div>
                <div className="table-modal-form-difference">(50%)</div>
              </div>
              <div className="table-modal-form-box">
                <label className="table-modal-form-title bold">
                  Preço de Custo:
                </label>
                <div className="table-modal-form-value bold">15.28€</div>
                <div className="table-modal-form-difference">($ PO)</div>
              </div>
              <div className="table-modal-form-button-box">
                <button
                  className="table-modal-form-button"
                  type="submit"
                >
                  update
                </button>
              </div>
            </form>
            <div className="table-modal-difference">
              <div className="table-modal-difference-title">
                Preço Concorrência:
              </div>
              <div className="table-modal-difference-cont">
                <div className="table-modal-difference-box">
                  <img
                    className="compa-arrow"
                    src="/icons/arrow-up.svg"
                    width="24px;"
                    alt=""
                  />
                  {/* <img
                    className="compa-arrow"
                    src="/icons/arrow-down.svg"
                    width="24px;"
                    alt=""
                  /> */}
                  <div className="table-modal-difference-text">
                    <div>Bodeboca:</div>
                    <div className="table-modal-difference-text-price">
                      46.80€
                    </div>
                  </div>
                </div>
                <div className="table-modal-difference-box">
                  <img
                    className="compa-arrow"
                    src="/icons/arrow-up.svg"
                    width="24px;"
                    alt=""
                  />
                  {/* <img
                    className="compa-arrow"
                    src="/icons/arrow-down.svg"
                    width="24px;"
                    alt=""
                  /> */}
                  <div className="table-modal-difference-text">
                    <div>Decantify PT:</div>
                    <div className="table-modal-difference-text-price">
                      42.80€
                    </div>
                  </div>
                </div>
                <div className="table-modal-difference-box">
                  <img
                    className="compa-arrow"
                    src="/icons/arrow-up.svg"
                    width="24px;"
                    alt=""
                  />
                  {/* <img
                    className="compa-arrow"
                    src="/icons/arrow-down.svg"
                    width="24px;"
                    alt=""
                  /> */}
                  <div className="table-modal-difference-text">
                    <div>Amazon ESP:</div>
                    <div className="table-modal-difference-text-price">
                      45.50€
                    </div>
                  </div>
                </div>
                <div className="table-modal-difference-box">
                  <img
                    className="compa-arrow"
                    src="/icons/arrow-up.svg"
                    width="24px;"
                    alt=""
                  />
                  {/* <img
                    className="compa-arrow"
                    src="/icons/arrow-down.svg"
                    width="24px;"
                    alt=""
                  /> */}
                  <div className="table-modal-difference-text">
                    <div>Garrafeira:</div>
                    <div className="table-modal-difference-text-price">
                      44.10€
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
