import React, { useState, useEffect } from "react";
import "./single-billing.css";
import { Preco } from "./Preco";
import { TooltipText } from "../../tables/gestao/Tooltip";
import { PaginationComponent } from "../../../ui/pagination/PaginationComponent";
import { getBilling } from "../../../../actions/billings";
import Preloader from "../../../ui/preloader/Preloader";
import CountryFlag from "../../../ui/country-flag/CountryFlag";

export const getBillingLogoSvg = (platformName: string) => {
    if (platformName === "B2C" || platformName === "B2B" || platformName.split(" ")[0].toLowerCase() === "vinuus") {
        return "shopify";
    } else {
        return platformName.split(" ")[0].toLowerCase();
    }
};

export const MarketplaceImage: React.FC<{ zeoosName: string; } & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>> = ({ zeoosName, ...props }) => {
    return (
        <img
            src={`https://vinuus-portal.s3.eu-west-3.amazonaws.com/production/assets/${getBillingLogoSvg(
                zeoosName
            )}.svg`}
            {...props}
        />
    );
};

interface Props {
    billingsId: number;
    setBillingsWindow: (billingsWindow: boolean) => void;
}

export const SingleBilling: React.FC<Props> = ({ setBillingsWindow, billingsId }) => {
    const rowsPerPage = 10;

    const [paginatedData, setPaginatedData] = useState<any>([]);
    const [billings, setBillings] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        async function initializeState() {
            try {
                const billings = await getBilling(billingsId);
                setPaginatedData(billings.slice(0, rowsPerPage));
                setBillings(billings);
            } catch (error) {
                console.error(error);
            }

            setLoading(false);
        }
        initializeState();
        // eslint-disable-next-line
    }, []);

    return loading ? (
        <Preloader />
    ) : (
        <>
            <div className="main-body">
                <div className="main-container">
                    <div className="table-main-cont whitebg">
                        <div className="single-billing-nav pointer" onClick={() => setBillingsWindow(false)}>
                            <img src="/icons/billing-arrow-back.svg" alt="" width="25px;" />
                            <div className="table-subtext underline pl2">Billing Manager</div>
                        </div>
                        <div className="table-value-search-cont">
                            <div className="table-value">{billings.length} {billings.length > 1 ? "Billings" : "Billing"} of shipment {billings[0].shipment_id}</div>
                        </div>
                        <div className="table-border-bottom"></div>
                        <div className="table-overflow">
                            <table className="table-results">
                                <thead>
                                    <tr className="table-results-title">
                                        <th className="single-billing-medium">Billing ID</th>
                                        <th className="table-border-right"></th>
                                        <th className="single-billing-medium">Billing Code / Name</th>
                                        <th className="table-border-right"></th>
                                        <th className="single-billing-large">Nome do produto</th>
                                        <th className="table-border-right"></th>
                                        <th className="single-billing-medium">Logo</th>
                                        <th className="table-border-right"></th>
                                        <th className="single-billing-medium">Canal</th>
                                        <th className="table-border-right"></th>
                                        <th className="single-billing-small">País</th>
                                        <th className="table-border-right"></th>
                                        <th className="single-billing-medium">Quantidades Vendidas</th>
                                        <th className="table-border-right"></th>
                                        <th className="single-billing-medium">Preço</th>
                                        <th className="table-border-right"></th>
                                        <th className="single-billing-medium">Data / Hora</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedData.map((item: any) => {
                                        return item.bill_line_ids.map((bill: any, index: number) => (
                                            <tr className="table-result-box billing-text" key={index}>
                                                <td className="single-billing-medium">
                                                    <div className="billing-text">{item.bill_id}</div>
                                                </td>
                                                <td className="table-border-right"></td>
                                                <td className="single-billing-medium">
                                                    <div className="billing-text">{item.bill_name}</div>
                                                </td>
                                                <td className="table-border-right"></td>
                                                <td className="single-billing-large justify-start pl1">
                                                    <div className="billing-text ">
                                                        <TooltipText text={bill.product_description} items={6} separator={" "} />
                                                    </div>
                                                </td>
                                                <td className="table-border-right"></td>
                                                <td className="single-billing-medium">
                                                    {item.platform_name && <MarketplaceImage
                                                        zeoosName={item.platform_name}
                                                        alt=""
                                                        width="50px"
                                                    />}
                                                </td>
                                                <td className="table-border-right"></td>
                                                <td className="single-billing-medium">
                                                    <div className="billing-text">{item.platform_name}</div>
                                                </td>
                                                <td className="table-border-right"></td>
                                                <td className="single-billing-small">
                                                    <CountryFlag
                                                        country={item.country}
                                                        className="table-search-image"
                                                        width="32px"
                                                    />
                                                </td>
                                                <td className="table-border-right"></td>
                                                <td className="single-billing-medium">
                                                    <div className="billing-text">{bill.quantity_sold}</div>
                                                </td>
                                                <td className="table-border-right"></td>
                                                <td className="single-billing-medium">
                                                    <Preco item={item} bill={bill} />
                                                </td>
                                                <td className="table-border-right"></td>
                                                <td className="single-billing-medium">
                                                    <div className="billing-text">{item.invoice_date}</div>
                                                    {/* <div className="billing-text">8:45 PM</div> */}
                                                </td>
                                            </tr>
                                        ));
                                    }
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <PaginationComponent data={billings} rowsPerPage={rowsPerPage} setPaginatedData={setPaginatedData} />
                    </div>
                </div>
            </div>
        </>
    );
};
