import React, { useContext, useEffect, useState } from 'react';
import * as _ from "lodash";
import { getAllRequests, sendUserRequest, cancelUserRequest } from '../../../../actions/notifications';
import { IRequest, IUser } from '../../../../interfaces';
import Preloader from '../../preloader/Preloader';
import moment from 'moment';
import "./requests.css";
import { Modal } from "../../../ui/modal/Modal";
import { UserContext } from '../../../context';

export const Requests = () => {
  const { user } = useContext(UserContext);

  const [requests, setRequests] = useState<IRequest[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function initializeState() {
      setLoading(true);
      let requests = await getAllRequests();
      setRequests(requests);
      setLoading(false);
    }
    initializeState();
    // eslint-disable-next-line
  }, []);

  const onStatusCallback = (newStatus: string, id: string) => {
    const updatedRequests = requests.map((req: IRequest) => {
      if (req._id === id) {
        req.status = newStatus;
      }
      return req;
    });
    setRequests(updatedRequests);
  }

  return loading ? (
    <Preloader />
  ) : (
    <div className="main-body">
      <div className="main-container">
        <div className="requests-container">
          <div className="requests-title">
            <h1>Requests</h1>
          </div>
          <RequestComponent requests={requests} user={user} onStatusCallback={onStatusCallback} />
        </div>
      </div>
    </div>
  );
}

interface Props {
  requests: IRequest[];
  user: IUser;
  onStatusCallback: (status: string, id: string) => void;
}

export const RequestComponent: React.FC<Props> = ({ requests, user, onStatusCallback }) => {
  const [modal, setModal] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("All statuses");
  const [adminType, setAdminType] = useState<string>("All types");
  const [filteredReq, setFilteredReq] = useState<IRequest[]>(requests);

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal(false);
  };

  function statusArray(array: IRequest[]) {
    return ["All statuses", ..._.uniq(_.map(array, (item: IRequest) => item.status))];
  }

  useEffect(() => {
    const _requests =
      status === "All statuses"
        ? requests
        : requests.filter((req: IRequest) => req.status === status);
    const filteredByAdminProperties =
      adminType === "All types"
        ? _requests
        : _requests.filter((req: IRequest) => req.type === adminType);
    setFilteredReq(filteredByAdminProperties);
    // eslint-disable-next-line
  }, [adminType, status]);

  const cancelRequest = async (e: React.MouseEvent, id: string) => {
    e.preventDefault();
    onStatusCallback("canceled", id);
    await cancelUserRequest(id);
  }

  return (
    <>
      <div className="requests-header">
        {requests.length !== 0 ? (
          <div className="requests-header-input">
            <label className="table-modal-form-title bold">Filter by:</label>
            <select
              className="requests-select"
              value={status}
              onChange={(e) => {
                e.preventDefault();
                setStatus(e.target.value);
                setAdminType("All types");
              }}
            >
              {statusArray(requests).map((item: string, index: number) => (
                <option key={index}>{item}</option>
              ))}
            </select>
          </div>
        ) : (<div></div>)}
        <div>
          <button className="requests-new-button" onClick={() => setModal(true)}>New request</button>
        </div>
      </div>
      <div className="requests-line"></div>
      {requests.length === 0 ? (
        <div className="requests-message">Ainda não há requests</div>
      ) : (
        <div className="requests-content">
          <div className="requests-content-cards">
            {[...filteredReq].reverse().map((req: IRequest, index: number) => {
              const content = req.type === "Product manipulations" ? JSON.parse(req.content) : req.content;
              return (
                <div className="requests-content-card" key={index}>
                  <div className="content-card-update">
                    <div className="card-update-title">{req.title}</div>
                    <div className="card-update-info">
                      {req.type === "Product manipulations" ? (
                        <span>Update sku {content.sku} with price {content.price} stock {content.stock} for marketplace {content.zeoosName}</span>
                      ) : (
                        <span>{content}</span>
                      )}
                    </div>
                  </div>
                  <div className="content-card-status">
                    <div className="card-status">{req.status}</div>
                    <div className="card-cancel">
                      <button className="card-cancel-button" onClick={(e) => cancelRequest(e, req._id)}>Cancel</button>
                    </div>
                    <div className="card-status-date">{moment(req.created).format('lll')}</div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
      {modal && (
        <NewRequest
          onModalClose={onModalClose}
          modal={modal}
          user={user}
        />
      )}
    </>
  )
}

interface INewRequest {
  onModalClose: any;
  modal: boolean;
  user: IUser;
}

export const NewRequest: React.FC<INewRequest> = ({ onModalClose, modal, user }) => {
  const [selectValue, setSelectValue] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");

  const sendRequest = async (e: React.MouseEvent) => {
    e.preventDefault();
    const newRequest = {
      userId: user._id,
      title,
      content,
      type: selectValue
    }
    await sendUserRequest(newRequest)
  }

  const selectOptions = ["Select an option", "Update product"]

  return (
    <Modal onModalClose={onModalClose} isOpened={modal}>
      <div className="newReq-body">
        <div className="newReq-container">
          <div className="newReq-title">Create request</div>
          <div className="newReq-content">
            <div className="content-select-request">
              <div className="table-modal-form-title bold">Select request type:</div>
              <select
                className="requests-select"
                value={selectValue}
                onChange={(e) => {
                  e.preventDefault();
                  setSelectValue(e.target.value);
                }}
              >
                {selectOptions.map((option: string, index: number) => (
                  <option key={index}>{option}</option>
                ))}
              </select>
            </div>
            <div className="content-title">
              <label className="table-modal-form-title bold">Title:</label>
              <input
                className="content-title-input"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="content-content">
              <label className="table-modal-form-title bold">Content:</label>
              <input
                className="content-content-input"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </div>
          </div>
          <div className="newReq-button">
            <button className="request-send-button" onClick={sendRequest}>Send</button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
