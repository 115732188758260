import React, { useContext, useEffect, useState } from "react";
import "./businessIntelligence.css";
import { LineChart } from "./LineChart";
import { OrderDetails } from "./OrderDetails";
import { Top5Chart } from "./Top5Chart";
import { GridValueGetterParams } from "@material-ui/data-grid";
import { ICombinedOrders, ICombinedOrder } from "../../../interfaces";
import { PSPChart } from "./PSPChart";
import { UserContext } from "../../context";

interface Props {
	orders: ICombinedOrders;
	avgOrderCounter: (array: ICombinedOrder[]) => string | number;
	grossValueCounter: (array: ICombinedOrder[]) => string | number;
	category: string;
	countryb2c: string;
	marketplaceMarket: string;
	marketplaceAccount: string;
}

export const B2CMarketplace: React.FC<Props> = ({
	orders,
	avgOrderCounter,
	grossValueCounter,
	category,
	countryb2c,
	marketplaceMarket,
	marketplaceAccount
}) => {
	const { user } = useContext(UserContext);

	const [totalOrders, setTotalOrders] = useState<string>("");
	const [avgOrderValue, setAvgOrderValue] = useState<string | number>();
	const [grossValue, setGrossValue] = useState<string | number>();
	const [selectedOrders, setSelectedOrders] = useState<Array<ICombinedOrder>>([]);

	const filteredByCategory =
		category === "All Categories"
			? orders.b2c.flatMap((x: ICombinedOrder) => x.sale_line_ids)
			: orders.b2c
				.flatMap((x: ICombinedOrder) => x.sale_line_ids)
				.filter((x: any) => x.category_id === category);

	useEffect(() => {
		const _orders =
			countryb2c === "All Countries"
				? orders.b2c
				: orders.b2c.filter((x: ICombinedOrder) => x.country === countryb2c);
		const _orders2 =
			marketplaceMarket === "All Webstores"
				? _orders
				: _orders.filter((x: ICombinedOrder) => x.sale_marketplace === marketplaceMarket);
		const selectedOrders =
			marketplaceAccount === "All Marketplaces"
				? _orders2
				: _orders2.filter(
					(x: ICombinedOrder) => x.sale_marketplace_account === marketplaceAccount
				);
		setSelectedOrders(selectedOrders);
		setTotalOrders(selectedOrders.length.toLocaleString("de-DE"));
		setAvgOrderValue(avgOrderCounter(selectedOrders));
		setGrossValue(grossValueCounter(selectedOrders));
		// eslint-disable-next-line
	}, [countryb2c, marketplaceMarket, marketplaceAccount]);

	return (
		<>
			<div className="bi__cards_group">
				<OrderDetails
					orders={selectedOrders}
					totalOrders={totalOrders}
					columns={user.role !== "ADMIN" ? columnsB2C.filter((column: any) => !["customer", "salesperson"].includes(column.field)) : columnsB2C}
				/>
				<div className="bi__card">
					<div className="bi__card_title">GMV</div>
					<div className="bi__card_content_avg">
						<div>
							<span>{grossValue} </span>
							<span className="bi__card_content_avg_currency">€</span>
						</div>
					</div>
				</div>
				<div className="bi__card">
					<div className="bi__card_title">Average Order Value</div>
					<div className="bi__card_content_avg">
						<div>
							<span>{avgOrderValue} </span>
							<span className="bi__card_content_avg_currency">€</span>
						</div>
					</div>
				</div>
				<LineChart array={selectedOrders} />
				<Top5Chart
					orders={selectedOrders}
					orders2={filteredByCategory}
					category={category}
				/>
				<PSPChart ordersList={selectedOrders} />
			</div>
		</>
	);
};

export interface IColumnsB2C {
	field: string;
	headerName: string;
	width: number;
	valueGetter: (params: GridValueGetterParams) => string;
}

export const columnsB2C = [
	{
		field: "sale_id",
		headerName: "id",
		width: 100,
		valueGetter: (params: GridValueGetterParams) => `${params.row.sale_id}`,
	},
	{
		field: "order_id",
		headerName: "Number",
		width: 150,
		valueGetter: (params: GridValueGetterParams) => `${params.row.order_id}`,
	},
	{
		field: "sale_date",
		headerName: "Creation Date",
		width: 180,
		valueGetter: (params: GridValueGetterParams) =>
			`${params.row.sale_date.slice(0, 10)}`,
	},
	{
		field: "country",
		headerName: "Country",
		width: 150,
		valueGetter: (params: GridValueGetterParams) => `${params.row.country}`,
	},
	{
		field: "sale_lengow_status",
		headerName: "Backend Order Status",
		width: 250,
		valueGetter: (params: GridValueGetterParams) =>
			`${params.row.sale_marketplace === "Shopify"
				? params.row.sale_shopify_status
				: params.row.sale_lengow_status
			}`,
	},
	{
		field: "sale_marketplace_account",
		headerName: "Backend Order Marketplace",
		width: 280,
		valueGetter: (params: GridValueGetterParams) =>
			`${params.row.sale_marketplace_account}`,
	},
	{
		field: "sale_lengow_id",
		headerName: "Backend Marketplace Order ID",
		width: 300,
		valueGetter: (params: GridValueGetterParams) =>
			`${params.row.sale_marketplace === "Shopify"
				? params.row.sale_shopify_id
				: params.row.sale_lengow_id
			}`,
	},
	{
		field: "customer",
		headerName: "Customer",
		width: 250,
		valueGetter: (params: GridValueGetterParams) => `${params.row.customer}`,
	},
	{
		field: "salesperson",
		headerName: "Salesperson",
		width: 250,
		valueGetter: (params: GridValueGetterParams) => `${params.row.salesperson}`,
	},
	{
		field: "totalQuantity",
		headerName: "Ordered Quantity",
		width: 200,
		valueGetter: (params: GridValueGetterParams) =>
			`${params.row.totalQuantity}`,
	},
	{
		field: "sale_amount",
		headerName: "Total",
		width: 130,
		valueGetter: (params: GridValueGetterParams) => `${params.row.sale_amount}`,
	},
];
